export function addGlobalEventListener(event_name, handler, options = {}) {
    if (!window.__USERBACK_SURVEY_EVENTS[event_name]) {
        window.__USERBACK_SURVEY_EVENTS[event_name] = [];
    }

    // Check if the handler is not already added
    if (!window.__USERBACK_SURVEY_EVENTS[event_name].includes(handler)) {
        window.__USERBACK_SURVEY_EVENTS[event_name].push(handler);

        // Add the real HTML event listener
        window.addEventListener(event_name, handler, options);
    }
}

export function isGlobalEventListenerAdded(event_name, handler) {
    return (
        window.__USERBACK_SURVEY_EVENTS &&
        window.__USERBACK_SURVEY_EVENTS[event_name] &&
        window.__USERBACK_SURVEY_EVENTS[event_name].some(
            existingHandler => existingHandler.name === handler.name
        )
    );
}

export function removeGlobalEventListener(event_name, handler) {
    if (window.__USERBACK_SURVEY_EVENTS &&
        window.__USERBACK_SURVEY_EVENTS[event_name]) {
        const index = window.__USERBACK_SURVEY_EVENTS[event_name].indexOf(handler);
        if (index !== -1) {
            window.__USERBACK_SURVEY_EVENTS[event_name].splice(index, 1);

            // Remove the real HTML event listener
            window.removeEventListener(event_name, handler);
        }
    }
}
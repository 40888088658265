import $ from 'jquery';

const Event_Recorder = {
    _started: false,
    _max_track_dom: 5,
    _max_track_event: 10,

    data: [],

    init: function() {
        this.bindEvent();
    },

    stop: function() {
        this.data = [];
        this.unbindEvent();
    },

    unbindEvent: function(elements) {
        $(document).off('mousedown', this.trackEvent);
    },

    bindEvent: function(elements) {
        $(document).on('mousedown', this.trackEvent);
    },

    trackEvent: function(e) {
        var do_ignore    = false;
        var ignore_class = ['userback-button-container', 'userback-comment', 'userback-overlay', 'userback-feedback-view'];

        ignore_class.forEach(function(class_name) {
            if ($(e.target).parents('.' + class_name).length || $(e.target).hasClass(class_name)) {
                do_ignore = true;
            }
        });

        if (do_ignore) {
            return;
        }

        if ($(e.target).parents('utoolbar').length || $(e.target).prop('tagName').toLowerCase() === 'utoolbar') {
            return;
        }

        if ($(e.target).parents('umodal').length || $(e.target).prop('tagName').toLowerCase() === 'umodal') {
            return;
        }

        if ($(e.target).prop('tagName').toLowerCase() === 'body') {
            return;
        }

        Event_Recorder.data.push({
            type: 'click',
            data: Event_Recorder.getEventData(e.target)
        });

        Event_Recorder.data = Event_Recorder.data.slice(0, Event_Recorder._max_track_event);
    },

    getEventData: function(element) {
        var element_tree = [element];
        var parent = this.getParent(element);

        while (parent !== false) {
            element_tree.push(parent);
            parent = this.getParent(parent);
        }

        element_tree = element_tree.slice(0, this._max_track_dom).reverse();

        var selector_names = [];
        element_tree.forEach((element) => {
            selector_names.push(this.getElementName(element));
        });

        return selector_names.join(' > ');
    },

    getElementName: function(element) {
        var name       = $(element).prop('tagName').toLowerCase();
        var id         = $(element).attr('id');
        var class_name = $(element).attr('class');

        if (id) {
            return name + '#' + id;
        } else if (class_name) {
            return name + '.' + class_name.replace(/ /g, '.');
        } else {
            return name;
        }
    },

    getParent: function(element) {
        var parent = $(element).parent();

        if (parent && parent.length &&
            parent.prop('tagName') &&
            parent.prop('tagName').toLowerCase() !== 'body' &&
            parent.prop('tagName').toLowerCase() !== 'html') {

            return parent.get(0);
        } else {
            return false;
        }
    },

    getAll: function() {
        return this.data;
    }
};

export default Event_Recorder;
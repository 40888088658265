// TODO: This will use the localstorage module in userback-app
export function saveLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}
  
export function loadLocalStorage(key) {
    try {
        const storedData = localStorage.getItem(key);

        if (storedData) {
            return JSON.parse(storedData);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

import { loadLocalStorage, saveLocalStorage } from './local';

// Save user information
export function record(survey_key, type = 'open') {
    // check whether Userback.user_data is used
    const user_data = readFingerPrint();
    const user_id = window.Userback?.user_data?.id || 0;
    const record_key = getItemKey(type);

    if (!user_data) {
        return;
    }

    // Append new user data
    let new_user_data = {};

    const existing_user_data = user_data[survey_key] || [];
    // check if user_id is already used
    const is_user_id_used = existing_user_data.some(item => item.user_id === user_id);

    if (is_user_id_used) {
        new_user_data = existing_user_data.map(item => {
            if (item.user_id === user_id) {
                const updated_item = {
                    ...item,
                    [record_key]: Date.now(),
                };
                if (type === 'submit') {
                    // when type is submit, update the last_close time as well
                    updated_item[getItemKey('close')] = Date.now();
                }

                return updated_item;
            }

            return item;
        });
    } else {
        // if user_id is not used, then add new user_id
        // if type is submit, also update the last_close time
        const new_item = {
            user_id: user_id,
            [record_key]: Date.now(),
            [getItemKey('open')]: Date.now(),
        };
        if (type === 'submit') {
            // when type is submit, update the last_close time as well
            new_item[getItemKey('close')] = Date.now();
        }

        new_user_data = [
            ...existing_user_data,
            new_item
        ];
    }

    saveLocalStorage('userback_finger_print', {
        ...user_data,
        [survey_key]: new_user_data
    });
}

// Is user eligible to open survey
export function isUsed(survey_key, type = 'open', recur = 0) {
    const user_data = readFingerPrint();

    // when survey_key is not found in user_data, it means is not used
    if (!user_data[survey_key]) {
        return false;
    }

    // when user_data is not empty, check whether user_id is the same
    const record_key = getItemKey(type);

    const user_id = window.Userback?.user_data?.id || 0;

    if (user_id) {
        // set up filter condition for user_data
        // this condtion is used to check whether user_id is the same and last_opened is within the recur time

        const used_condition = (item) => {
            if (item.user_id !== user_id) {
                return false;
            }

            let time_record = item[record_key];
            // when type is close and there is no close time, just check the last_submit time
            if (type === 'close' && !time_record) {
                time_record = item.last_submit;
            }

            if (item.user_id === user_id && time_record === 0) {
                return false;
            }

            if (item.user_id === user_id && time_record > 0) {
                if (recur > 0) {
                    return time_record + recur > Date.now();
                }
                return true;
            }
        };

        const used_by_user = user_data[survey_key].some(used_condition);

        if (used_by_user) {
            return true;
        }
    } else {
        // when user_id is not found, then check whether last_opened is within the recur time
        const used_condition = (item) => {
            // only works if user_id is 0
            if (item.user_id !== 0) {
                return false;
            }

            let time_record = item[record_key];
            // when type is close and there is no close time, just check the last_submit time
            if (type === 'close' && !time_record) {
                time_record = item.last_submit;
            }

            if (time_record === 0) {
                return false;
            }

            if (recur > 0) {
                return time_record + recur > Date.now();
            }

            return true;
        };

        const used = user_data[survey_key].some(used_condition);

        if (used) {
            return true;
        }
    }

    return false;
}

function getItemKey(type) {
    switch (type) {
        case 'close':
            return 'last_closed';
        case 'submit':
            return 'last_submit';
        default:
            return 'last_opened';
    }
}

function readFingerPrint() {
    const user_data = loadLocalStorage('userback_finger_print');

    if (!user_data) {
        return {};
    }

    if (!isValidFingerPrint(user_data)) {
        return {};
    }

    return user_data;
}

function isValidFingerPrint(data) {
    if (!data) {
        return false;
    }

    if (typeof data !== 'object') {
        return false;
    }

    for (const key in data) {
        if (!Array.isArray(data[key])) {
            return false;
        }

        for (const item of data[key]) {
            if (typeof item !== 'object') {
                return false;
            }

            // Check if the object has the required properties
            if (!item.hasOwnProperty('last_opened') || !item.hasOwnProperty('user_id')) {
                return false;
            }
        }
    }

    return true;
}
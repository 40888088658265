import Lib from 'lib/lib';
import Session_Recorder from 'component/session_recorder';

const Console_Recorder = {
    _track_type: ['log', 'error', 'warn', 'debug', 'info'],

    data: [],

    init: function() {
        if (typeof console === 'undefined') {
            return;
        }

        try {
            if (typeof window.console._std === 'undefined') {
                window.console._std = {
                    log   : window.console.log.bind(window.console),
                    error : window.console.error.bind(window.console),
                    warn  : window.console.warn.bind(window.console),
                    debug : window.console.debug.bind(window.console),
                    info  : window.console.info.bind(window.console),
                };
            }

            this.error_handler = function(e) {
                if (!e) {
                    return;
                }

                if (Lib.isChrome()) {
                    if (typeof e.error !== 'undefined' &&
                        e.error &&
                        typeof e.error.stack !== 'undefined') {

                        Console_Recorder.track('error', [e.error.stack]);
                    }
                } else {
                    if (typeof e.message !== 'undefined') {
                        Console_Recorder.track('error', [e.message + (typeof e.filename !== 'undefined' ? "\r\n    " + e.filename + ':' + e.lineno : '')]);
                    }
                }
            };

            // log
            window.console.log = function() {
                window.console._std.log.apply(window.console, arguments);
                Console_Recorder.track('log', Array.from(arguments));
            };

            // error
            window.console.error = function() {
                window.console._std.error.apply(window.console, arguments);
                Console_Recorder.track('error', Array.from(arguments));
            };

            window.addEventListener('error', this.error_handler);

            // warning
            window.console.warn = function() {
                window.console._std.warn.apply(window.console, arguments);
                Console_Recorder.track('warn', Array.from(arguments));
            };

            // debug
            window.console.debug = function() {
                window.console._std.debug.apply(window.console, arguments);
                Console_Recorder.track('debug', Array.from(arguments));
            };

            // info
            window.console.info = function() {
                window.console._std.info.apply(window.console, arguments);
                Console_Recorder.track('info', Array.from(arguments));
            };
        } catch (e) {
        }
    },

    stop: function(type) {
        if (typeof type === 'undefined') {
            // stop all
            this._track_type = [];
            this.data = [];

            window.console.log   = window.console._std.log;
            window.console.error = window.console._std.error;
            window.console.warn  = window.console._std.warn;
            window.console.debug = window.console._std.debug;
            window.console.info  = window.console._std.info;

            window.removeEventListener('error', this.error_handler);
        } else {
            var index = this._track_type.indexOf(type);

            if (index !== -1) {
                this._track_type.splice(index, 1);
            }

            var new_data = [];
            this.data.forEach(function(data) {
                if (data.type !== type) {
                    new_data.push(data);
                }
            });
            this.data = new_data;

            switch (type) {
                case 'log':
                    window.console.log = window.console._std.log;
                    break;
                case 'error':
                    window.console.error = window.console._std.error;
                    window.removeEventListener('error', this.error_handler);
                    break;
                case 'warn':
                    window.console.warn = window.console._std.warn;
                    break;
                case 'debug':
                    window.console.debug = window.console._std.debug;
                    break;
                case 'info':
                    window.console.info = window.console._std.info;
                    break;
            }
        }
    },

    track: function(type, data) {
        if (this._track_type.indexOf(type) === -1) {
            return;
        }

        var data = Console_Recorder.validate(data);

        if (data && data.length) {
            this.data.push({
                type: type,
                data: data
            });
        }
    },

    getAll: function() {
        return this.data;
    },

    validate: function(data) {
        var response = [];

        for (var i = 0; i < data.length; i++) {
            var raw_data = data[i];
            var new_data_value;
            var new_data_type;

            try {
                switch (typeof raw_data) {
                    case 'number':
                        new_data_value = raw_data;
                        new_data_type  = 'number';
                        break;
                    case 'string':
                        new_data_value = raw_data;
                        new_data_type  = 'string';
                        break;
                    case 'boolean':
                        new_data_value = raw_data;
                        new_data_type  = 'boolean';
                        break;
                    case 'function':
                        new_data_value = 'function' + (typeof raw_data.name === 'string' ? ' ' + raw_data.name + '() {...}' : '');
                        new_data_type  = 'function';
                        break;
                    case 'object':
                        if (raw_data === null) {
                            new_data_value = 'null';
                            new_data_type  = 'null';
                        } else if (raw_data instanceof HTMLElement) {
                            new_data_value = '<' + raw_data.tagName.toLowerCase();
                            for (var z = 0; z < raw_data.attributes.length; z++) {
                                new_data_value += ' ' + raw_data.attributes[z].nodeName;

                                if (raw_data.attributes[z].nodeValue) {
                                    new_data_value += '="' + raw_data.attributes[z].nodeValue + '"';
                                }
                            }
                            new_data_value += '>...</' + raw_data.tagName.toLowerCase() + '>';
                            new_data_type  = 'dom';
                        } else if (raw_data instanceof HTMLDocument) {
                            new_data_value = '#document';
                            new_data_type  = 'dom';
                        } else {
                            new_data_value = JSON.stringify(raw_data);
                            new_data_type  = 'json';
                        }

                        break;
                    case 'undefined':
                        new_data_value = 'undefined';
                        new_data_type  = 'undefined';
                        break;
                }

            } catch (e) {
            }

            if (new_data_value && new_data_type) {
                response.push({
                    val  : new_data_value.length > 4000 ? new_data_value.substring(0, 4000) + '...' : new_data_value,
                    type : new_data_type
                });
            }
        }

        return response;
    }
};

export default Console_Recorder;
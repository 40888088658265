const Config = {
    request_url  : 'https://api.userback.io', // widget request url
    track_url    : 'https://events.userback.io', // session replay request url
    proxy_url    : 'https://proxy-screenshot.userback.io',
    widget_css   : 'https://static.userback.io/widget/v1.css',
    survey_url   : 'https://app.userback.io/s/',
    access_token : '',
    load_type    : 'web', // web, chrome_extension, firefox_extension, edge_extension
    demo_mode    : false,

    init: function() {
        if (window.Userback.request_url !== undefined) {
            this.request_url = window.Userback.request_url.toString().replace(/\/$/, ''); // remove trailing slash
        }

        if (window.Userback.track_url !== undefined) {
            this.track_url = window.Userback.track_url.toString().replace(/\/$/, ''); // remove trailing slash
        }

        if (window.Userback.survey_url !== undefined) {
            this.survey_url = window.Userback.survey_url.toString().replace(/\/$/, ''); // remove trailing slash
        }

        if (window.Userback.proxy_url !== undefined) {
            this.proxy_url = window.Userback.proxy_url;
        }

        if (window.Userback.widget_css !== undefined) {
            this.widget_css = window.Userback.widget_css;
        }

        if (window.Userback.access_token !== undefined) {
            this.access_token = window.Userback.access_token.toString();
        }

        if (window.Userback.load_type !== undefined) {
            this.load_type = window.Userback.load_type;
        }

        if (window.Userback.demo_mode !== undefined) {
            this.demo_mode = !!window.Userback.demo_mode;
        }
    }
};

export default Config;
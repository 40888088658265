const Lang = {
    language: 'en',

    'en': {
        draw_on_the_screen: 'Draw on the screen',
        draw_on_the_screen_help: 'Give feedback with a screenshot',

        capture_video: 'Screen recording',
        capture_video_help: 'Give feedback with a screen recording',

        general_feedback: 'General feedback',
        general_feedback_help: 'Give general feedback of this page',

        report_bug: 'Report a bug',
        report_bug_help: 'Let us know what\'s broken',

        feature_request: 'Feature request',
        feature_request_help: 'Tell us how we can improve',

        contact_us: 'Contact us',
        contact_us_help: 'Get in touch with us',

        view_other_feedback: 'View existing feedback',
        view_other_feedback_help: 'See what\'s already been submitted',

        your_name: 'Your name',
        your_email_address: 'Your email address',
        feedback_title: 'Add a title',
        select_a_category: 'Select a category',
        select_a_priority: 'Choose priority',
        assign_to: 'Assign to',
        leave_us_your_comment: 'Leave us your comment',
        attach_a_screenshot: 'Screenshot',
        screenshot_attached: 'screenshot',

        send:    'Send feedback',
        back:    'back',
        remove:  'Remove',
        close:   'Close',
        next:    'Next',
        send_success: 'Thanks for the feedback!',

        highlight           : 'Highlight',
        blackout            : 'Blackout',
        pencil              : 'Pencil',
        line                : 'Line',
        arrow               : 'Arrow',
        add_a_comment       : 'Add a comment',
        capture_screenshot  : 'Capture screenshot',

        save:   'Save',
        delete: 'delete',
        add_your_comment_here: 'Enter your comment...',

        attach_a_file: 'Attach a file',
        file_format_not_supported: 'File format not supported',
        max: 'Max',

        open_feedback: 'feedback',
        search: 'Search...',
        cancel: 'Cancel',
        feedback_not_found: 'Oops, no feedback could be found.',
        feedback_type_all: 'All',
        feedback_type_general: 'General',
        feedback_type_bug: 'Bug Report',
        feedback_type_feature_request: 'Feature Request',
        feedback_type_screenshot: 'Screenshot',
        feedback_type_video: 'Video',
        this_page_only: 'This page only',

        want_to_leave: 'You have unsaved changes',
        changes_not_saved: 'Are you sure that you want to close?',
        discard: 'Discard',

        start_recording: 'Start recording',
        screen_recording: 'Screen recording',

        recording_mute: 'Turn off microphone',
        recording_unmute: 'Turn on microphone',
        recording_pause: 'Pause',
        recording_resume: 'Resume',
        recording_draw: 'Draw',
        recording_finish: 'Finish recording',

        video_not_supported: 'Video recording is not supported',
        video_requires_https: 'A secure HTTPS connection is required for video recording.',
        ok: 'OK'
    },

    // Simplified Chinese
    'zh-CN': {
        draw_on_the_screen: '在屏幕上绘制',
        draw_on_the_screen_help: '提供对指定本页面特定区域的反馈意见',

        capture_video: '录制屏幕',
        capture_video_help: '将您的反馈意见录制成一段录像',

        general_feedback: '基本反馈',
        general_feedback_help: '提供对本页面一般反馈意见',

        report_bug: '报告错误',
        report_bug_help: '让我们知道什么坏了',

        feature_request: '改进建议',
        feature_request_help: '我们如何可以改进',

        contact_us: '联系我们',
        contact_us_help: '和我们取得联系',

        view_other_feedback: '查看其它',
        view_other_feedback_help: '查看已经提交的反馈',

        your_name: '姓名',
        your_email_address: '邮箱地址',
        feedback_title: '添加标题',
        select_a_category: '选择类别',
        select_a_priority: '选择优先级',
        assign_to: '指派给',
        leave_us_your_comment: '请留下评论...',
        attach_a_screenshot : '附加截屏',
        screenshot_attached: '截屏',

        send:    '发送',
        back:    '返回',
        remove:  '删除',
        close:   '关闭',
        next:    '下一步',
        send_success: '谢谢您的反馈!',

        highlight          : '高亮',
        blackout           : '涂黑',
        pencil             : '笔',
        line               : '直线',
        arrow              : '箭头',
        add_a_comment      : '评论',
        capture_screenshot : '截屏',

        save:   '保存',
        delete: '删除',
        add_your_comment_here: '评论...',

        attach_a_file: '上传文件',
        file_format_not_supported: '文件格式不支持',
        max: '最大',

        open_feedback: '个反馈意见',
        search: '查找...',
        cancel: '关闭',
        feedback_not_found: '搜索无结果',
        feedback_type_all: '所有',
        feedback_type_general: '基本反馈',
        feedback_type_bug: '错误报告',
        feedback_type_feature_request: '改进建议',
        feedback_type_screenshot: '屏幕截图',
        feedback_type_video: '屏幕录制',
        this_page_only: '仅此页',

        want_to_leave: '有更改尚未保存',
        changes_not_saved: '确定要关闭吗？',
        discard: '舍弃',

        start_recording: '开始录制',

        recording_mute: '关闭麦克风',
        recording_unmute: '打开麦克风',
        recording_pause: '暂停',
        recording_resume: '开始',
        recording_draw: '绘图',
        recording_finish: '结束录制'
    },

    // Tranditional Chinese
    'zh-TW': {
        draw_on_the_screen: '在屏幕上繪制',
        draw_on_the_screen_help: '提供對指定本頁面特定區域的反饋意見',

        capture_video: '錄製屏幕',
        capture_video_help: '將您的反饋意見錄製成一段錄像',

        general_feedback: '基本反饋',
        general_feedback_help: '提供對本頁面一般反饋意見',

        report_bug: '報告錯誤',
        report_bug_help: '讓我們知道什麼壞了',

        feature_request: '改進建議',
        feature_request_help: '我們如何可以改進',

        contact_us: '聯繫我們',
        contact_us_help: '和我們取得聯繫',

        view_other_feedback: '查看其它',
        view_other_feedback_help: '查看已經提交的反饋',

        your_name: '姓名',
        your_email_address: '郵箱地址',
        select_a_category: '選擇類別',
        select_a_priority: '選擇優先級',
        assign_to: '指派給',
        leave_us_your_comment: '請留下評論...',
        attach_a_screenshot : '附加截屏',
        screenshot_attached: '截屏',

        send:    '發送',
        back:    '返回',
        remove:  '删除',
        close:   '關閉',
        next:    '下一步',
        send_success: '謝謝您的反饋!',

        highlight          : '高亮',
        blackout           : '塗黑',
        pencil             : '筆',
        line               : '直線',
        arrow              : '箭頭',
        add_a_comment      : '評論',
        capture_screenshot : '截屏',

        save:   '保持',
        delete: '删除',
        add_your_comment_here: '評論...',

        attach_a_file: '上傳文件',
        file_format_not_supported: '文件格式不支持',
        max: '最大',

        open_feedback: '個反饋意見',
        search: '查找...',
        cancel: '關閉',
        feedback_not_found: '搜索無結果',
        feedback_title: '添加標題',
        feedback_type_all: '所有',
        feedback_type_general: '基本反饋',
        feedback_type_bug: '錯誤報告',
        feedback_type_feature_request: '改進建議',
        feedback_type_screenshot: '屏幕截圖',
        feedback_type_video: '屏幕錄製',
        this_page_only: '僅此頁',

        want_to_leave: '你的變更尚未儲存',
        changes_not_saved: '確定要關閉嗎？',
        discard: '捨棄',

        start_recording: '開始錄製',

        recording_mute: '關閉麥克風',
        recording_unmute: '打開麥克風',
        recording_pause: '暫停',
        recording_resume: '開始',
        recording_draw: '繪圖',
        recording_finish: '結束錄製'
    },

    // Korean
    'ko': {
        draw_on_the_screen: '화면에 그려서 표시하기',
        draw_on_the_screen_help: '이 페이지의 특정 부분에 대한 피드백을 제출합니다',

        general_feedback: '일반적인 피드백 남기기',
        general_feedback_help: '이 페이지에 대한 일반적인 피드백을 제출합니다',

        capture_video: '녹화 화면',
        capture_video_help: '화면 기록으로 피드백 제공',

        report_bug: '오류를 보고하다',
        report_bug_help: '고장난 것을 알려주십시오',

        feature_request: '기능 요청',
        feature_request_help: '어떻게 개선할 수 있습니까',

        contact_us: '문의하기',
        contact_us_help: '우리와 연락하다',

        view_other_feedback: '다른 피드백 확인하기',
        view_other_feedback_help: '이미 제출 된 내용보기',

        your_name: '당신의 이름',
        your_email_address: '이메일 주소',
        select_a_category: '카테고리를 선택하세요',
        select_a_priority: '우선 순위 선택',
        assign_to: '에 할당',
        leave_us_your_comment: '의견을 남겨주세요...',
        attach_a_screenshot : '스크린 샷 첨부',
        screenshot_attached: '스크린 샷',

        send:    '보내기',
        back:    '돌아가기',
        remove:  '풀다',
        close:   '닫기',
        next:    '다음',
        send_success: '귀하의 의견에 감사드립니다!',

        save:   '저장하기',
        delete: '삭제하기',
        add_your_comment_here: '의견을 남겨주세요...',

        attach_a_file: '파일 첨부하기',
        file_format_not_supported: '이 형식의 파일은 지원하지 않습니다',
        max: '최대',

        open_feedback: '개의 공개 피드백이 있습니다',
        search: '검색해보세요...',
        cancel: '취소하기',
        feedback_title: '제목 추가',
        feedback_not_found: '와 일치하는 피드백이 없습니다',
        feedback_type_all: '모두',
        feedback_type_general: '일반적인 피드백 남기기',
        feedback_type_bug: '버그 신고',
        feedback_type_feature_request: '기능 요청',
        feedback_type_screenshot: '스크린 샷',
        feedback_type_video: '화면 녹화',
        this_page_only: '이 페이지 만',

        want_to_leave: '저장되지 않은 변경사항이 있음',
        changes_not_saved: '닫으시겠습니까?',
        discard: '삭제',

        start_recording: '스타트',

        recording_mute: '關閉麥克風',
        recording_unmute: '打開麥克風',
        recording_pause: '暫停',
        recording_resume: '開始',
        recording_draw: '무승부',
        recording_finish: '結束錄製'
    },

    // French
    'fr': {
        draw_on_the_screen: 'Dessiner sur l\'écran',
        draw_on_the_screen_help: 'Commenter sur des domaines spécifiques de cette page',

        general_feedback: 'Impressions générales',
        general_feedback_help: 'Donnez une évaluation générale de cette page',

        capture_video: 'Écran d\'enregistrement',
        capture_video_help: 'Fournir des commentaires avec enregistrement d\'écran',

        report_bug: 'Signaler un bug',
        report_bug_help: 'Faites-nous savoir ce qui est cassé',

        feature_request: 'Demande de fonctionnalité',
        feature_request_help: 'Comment pouvons-nous améliorer',

        contact_us: 'Contactez nous',
        contact_us_help: 'Prenez contact avec nous',

        view_other_feedback: 'Voir d\'autres commentaires',
        view_other_feedback_help: 'Voir ce qui a déjà été soumis',

        your_name: 'Votre nom',
        your_email_address: 'Votre adresse email',
        select_a_category: 'Selectionner une catégorie',
        select_a_priority: 'Choisissez la priorité',
        assign_to: 'Assigner à',
        leave_us_your_comment: 'Laisser votre commentaire...',
        attach_a_screenshot : 'joindre une capture d\'écran',
        screenshot_attached: 'capture d\'écran',

        send:    'Envoyer',
        back:    'Retour',
        remove:  'Retirer',
        close:   'Fermer',
        next:    'Suivant',
        send_success: 'Merci pour vos commentaires!',

        save:   'Sauvegarder',
        delete: 'Effacer',
        add_your_comment_here: 'Ajouter votre commentaire ici...',

        attach_a_file: 'Attacher un fichier',
        file_format_not_supported: 'Format de fichier non supporte',
        max: 'Maximum',

        open_feedback: 'Commentaires libres',
        search: 'Chercher...',
        cancel: 'Annulation',
        feedback_title: 'Ajouter un titre',
        feedback_not_found: 'Oops, aucun feedback n\'a été trouvé.',
        feedback_type_all: 'Toute',
        feedback_type_general: 'Impressions generales',
        feedback_type_bug: 'Rapport d\'erreur',
        feedback_type_feature_request: 'Demande de fonctionnalité',
        feedback_type_screenshot: 'Capture d\'écran',
        feedback_type_video: 'Enregistrement d\'écran',
        this_page_only: 'Cette page uniquement',

        want_to_leave: 'Certaines modifications n\'ont pas été enregistrées',
        changes_not_saved: 'Voulez-vous vraiment fermer?',
        discard: 'Supprimer',

        start_recording: 'Début',

        recording_mute: 'Muet',
        recording_unmute: 'Réactiver',
        recording_pause: 'Pause',
        recording_resume: 'Reprendre',
        recording_draw: 'Dessiner',
        recording_finish: 'Terminer l\'enregistrement'
    },

    // Russian
    'ru': {
        draw_on_the_screen: 'Нарисуйте на экране',
        draw_on_the_screen_help: 'Оставьте отзыв о конкретных местах на данной странице',

        general_feedback: 'Общий отзыв',
        general_feedback_help: 'Оставьте общий отзыв о странице',

        capture_video: 'Экран записи',
        capture_video_help: 'Обеспечить обратную связь с записью экрана',

        report_bug: 'Сообщить об ошибке',
        report_bug_help: 'Сообщите нам, что сломано',

        feature_request: 'Запрос функции',
        feature_request_help: 'Как мы можем улучшить',

        contact_us: 'Связаться с нами',
        contact_us_help: 'Свяжись с нами',

        view_other_feedback: 'Смотреть другие отзывы',
        view_other_feedback_help: 'Посмотрите, что уже было отправлено',

        your_name: 'Ваше имя',
        your_email_address: 'Адрес эл. почты',
        select_a_category: 'Выберите категорию',
        select_a_priority: 'Выбрать приоритет',
        assign_to: 'Назначить в',
        leave_us_your_comment: 'Оставьте комментарий...',
        attach_a_screenshot : 'приложить скриншот',
        screenshot_attached: 'cкриншот',

        send:    'Отправить',
        back:    'Назад',
        remove:  'Удалить',
        close:   'Закрыть',
        next:    'Далее',
        send_success: 'Спасибо за ваш отзыв!',

        save:   'Сохранить',
        delete: 'Стереть',
        add_your_comment_here: 'Оставьте Ваш комментарий',

        attach_a_file: 'Прикрепить файл',
        file_format_not_supported: 'Формат файла не поддерживается',
        max: 'Макс',

        open_feedback: 'отзыв',
        search: 'Поиск...',
        cancel: 'Отменить',
        feedback_title: 'Добавить заголовок',
        feedback_not_found: 'Ой! Не найдено ни одного подходящего отзыва',
        feedback_type_all: 'Все',
        feedback_type_general: 'Общий отзыв',
        feedback_type_bug: 'Сообщение об ошибке',
        feedback_type_feature_request: 'Как мы можем улучшить',
        feedback_type_screenshot: 'Скриншот',
        feedback_type_video: 'Запись экрана',
        this_page_only: 'Только эта страница',

        want_to_leave: 'Есть несохраненные изменения',
        changes_not_saved: 'Закрыть это окно?',
        discard: 'Закрыть',

        start_recording: 'Начните',

        recording_mute: 'Выключить микрофон',
        recording_unmute: 'Включить микрофон',
        recording_pause: 'Пауза',
        recording_resume: 'Возобновить',
        recording_draw: 'Pисовать',
        recording_finish: 'Завершить запись'
    },

    // Dutch
    'nl': {
        draw_on_the_screen: 'Teken op het scherm',
        draw_on_the_screen_help: 'Teken of markeer specifieke gedeelten op deze pagina',

        general_feedback: 'Algemene feedback',
        general_feedback_help: 'Geef je feedback voor deze pagina',

        capture_video: 'Record scherm',
        capture_video_help: 'Geef feedback met schermrecord',

        report_bug: 'Een bug melden',
        report_bug_help: 'Laat ons weten wat er kapot is',

        feature_request: 'Functieverzoek:',
        feature_request_help: 'Hoe kunnen we verbeteren',

        contact_us: 'Neem contact met ons op',
        contact_us_help: 'Neem contact op met ons',

        view_other_feedback: 'Bekijk andere feedback',
        view_other_feedback_help: 'Bekijk wat er al is ingediend',

        your_name: 'Uw naam',
        your_email_address: 'Jouw e-mailadres',
        select_a_category: 'Selecteer een categorie',
        select_a_priority: 'Kies prioriteit',
        assign_to: 'Rechtverkrijgende',
        leave_us_your_comment: 'Laat hier uw feedback achter....',
        attach_a_screenshot : 'voeg een screenshot toe',
        screenshot_attached: 'screenshot',

        send:    'Verzenden',
        back:    'Terug',
        remove:  'Verwijderen',
        close:   'Sluiten',
        next:    'Volgende',
        send_success: 'Bedankt voor uw feedback!',

        save:   'Opslaan',
        delete: 'Verwijderen',
        add_your_comment_here: 'Plaats je reactie hier...',

        attach_a_file: 'Bijlage toevoegen',
        file_format_not_supported: 'Bestandsformaat niet ondersteund',
        max: 'Max',

        open_feedback: 'feedback',
        search: 'Zoeken...',
        cancel: 'Annuleren',
        feedback_title: 'Titel toevoegen',
        feedback_not_found: 'Oeps, geen overeenkomende resultaten gevonden.',
        feedback_type_all: 'Allemaal',
        feedback_type_general: 'Algemene feedback',
        feedback_type_bug: 'Bug report',
        feedback_type_feature_request: 'Functieverzoek:',
        feedback_type_screenshot: 'Screenshot',
        feedback_type_video: 'Schermopname',
        this_page_only: 'Alleen deze pagina',

        want_to_leave: 'De wijzigingen zijn nog niet opgeslagen',
        changes_not_saved: 'Weet je zeker dat je wilt afsluiten?',
        discard: 'Verwijderen',

        start_recording: 'Begin',

        recording_mute: 'Schakel de microfoon uit',
        recording_unmute: 'Zet de microfoon aan',
        recording_pause: 'Pauze',
        recording_resume: 'Hervat',
        recording_draw: 'Trek',
        recording_finish: 'Voltooi de opname'
    },

    // Portuguese
    'pt': {
        draw_on_the_screen: 'Desenhe no Ecrã',
        draw_on_the_screen_help: 'Escreva o seu comentário sobre áreas específicas desta página',

        general_feedback: 'Comentário Geral',
        general_feedback_help: 'Escreva o seu comentário geral sobre esta página',

        capture_video: 'Tela de gravação',
        capture_video_help: 'Fornecer feedback com o registro de tela',

        report_bug: 'Reportar um erro',
        report_bug_help: 'Deixe-nos saber o que está quebrado',

        feature_request: 'Solicitação de recurso',
        feature_request_help: 'Como podemos melhorar',

        contact_us: 'Contate-Nos',
        contact_us_help: 'Entre em contato conosco',

        view_other_feedback: 'Veja outros Comentários',
        view_other_feedback_help: 'Veja o que já foi enviado',

        your_name: 'Seu nome',
        your_email_address: 'O seu Email',
        select_a_category: 'Escolha uma Categoria',
        select_a_priority: 'Escolha a prioridade',
        assign_to: 'Atribuir a',
        leave_us_your_comment: 'Deixe-nos o seu Comentário...',
        attach_a_screenshot : 'anexar uma captura de tela',
        screenshot_attached: 'screenshot',

        send:    'Envia',
        back:    'Voltar',
        remove:  'Remover',
        close:   'Fechar',
        next:    'Seguinte',
        send_success: 'Obrigado pelo seu feedback!',

        save:   'Gravar',
        delete: 'Apagar',
        add_your_comment_here: 'Adicione o seu comentário aqui...',

        attach_a_file: 'Anexe o seu Ficheiro',
        file_format_not_supported: 'Formato do Ficheiro não é suportado',
        max: 'Max',

        open_feedback: 'comentários abertos',
        search: 'Pesquisa...',
        cancel: 'Cancelar',
        feedback_title: 'Adicionar um título',
        feedback_not_found: 'Nenhums commentários foram achados',
        feedback_type_all: 'Todos',
        feedback_type_general: 'Comentário Geral',
        feedback_type_bug: 'Relatório de erro',
        feedback_type_feature_request: 'Solicitação de recurso',
        feedback_type_screenshot: 'Captura de tela',
        feedback_type_video: 'Gravação de tela',
        this_page_only: 'Somente esta página',

        want_to_leave: 'Tem alterações não guardadas',
        changes_not_saved: 'Tem a certeza de que pretende fechar?',
        discard: 'Rejeitar',

        start_recording: 'Começar',

        recording_mute: 'Ligue o microfone',
        recording_unmute: 'Desligue o microfone',
        recording_pause: 'Pausa',
        recording_resume: 'Retomar',
        recording_draw: 'Desenhar',
        recording_finish: 'Terminar gravação'
    },

    // Portuguese
    'pt-br': {
        draw_on_the_screen: 'Desenhe na Tela',
        draw_on_the_screen_help: 'Dar feedback com uma cópia da tela',

        general_feedback: 'Feedback geral',
        general_feedback_help: 'Dar feedback gravando um vídeo',

        capture_video: 'Capturar vídeo',
        capture_video_help: 'Dar feedback gravando um vídeo',

        report_bug: 'Reportar um erro',
        report_bug_help: 'Deixe-nos saber o que está quebrado',

        feature_request: 'Solicitação de recurso',
        feature_request_help: 'Como podemos melhorar',

        contact_us: 'Entre em Contato',
        contact_us_help: 'Entre em contato conosco',

        view_other_feedback: 'Exibir outros feedbacks',
        view_other_feedback_help: 'Veja o que já foi enviado',

        your_name: 'Seu nome',
        your_email_address: 'Seu endereço de e-mail',
        select_a_category: 'Selecione uma categoria',
        select_a_priority: 'Escolha a prioridade',
        assign_to: 'Atribuir a',
        leave_us_your_comment: 'Deixe o seu comentário...',
        attach_a_screenshot : 'Incluir uma cópia de tela',
        screenshot_attached: 'screenshot',

        send:    'Enviar',
        back:    'Voltar',
        remove:  'Remover',
        close:   'Fechar',
        next:    'Próximo',
        send_success: 'Obrigado pelo seu feedback!',

        save:   'Salvar',
        delete: 'Apagar',
        add_your_comment_here: 'Digite seu comentário...',

        attach_a_file: 'Anexar um Arquivo',
        file_format_not_supported: 'Formato do Ficheiro não é suportado',
        max: 'Max',

        open_feedback: 'feedbacks abertos',
        search: 'Pesquisar...',
        cancel: 'Cancelar',
        feedback_title: 'Adicionar um título',
        feedback_not_found: 'Ops, nenhum feedback encontrado.',
        feedback_type_all: 'Todos',
        feedback_type_general: 'Comentário Geral',
        feedback_type_bug: 'Relatório de erro',
        feedback_type_feature_request: 'Solicitação de recurso',
        feedback_type_screenshot: 'Captura de tela',
        feedback_type_video: 'Gravação de tela',
        this_page_only: 'Somente esta página',

        want_to_leave: 'Tem alterações não guardadas',
        changes_not_saved: 'Tem a certeza de que pretende fechar?',
        discard: 'Rejeitar',

        start_recording: 'Iniciar Gravação',

        recording_mute: 'Ligue o microfone',
        recording_unmute: 'Desligue o microfone',
        recording_pause: 'Pausa',
        recording_resume: 'Retomar',
        recording_draw: 'Desenhar',
        recording_finish: 'Terminar gravação'
    },

    // Lithuanian
    'lt': {
        draw_on_the_screen: 'Piešti ant ekrano',
        draw_on_the_screen_help: 'Pateikite atsiliepimą apie konkrečią vietą šiame puslapyje',

        general_feedback: 'Bendras atsiliepimas',
        general_feedback_help: 'Pateikite šio puslapio atsiliepimą',

        capture_video: 'Įrašymo ekranas',
        capture_video_help: 'Pateikite atsiliepimą su ekrano įrašu',

        report_bug: 'Pranešk apie problemą',
        report_bug_help: 'Praneškite mums, kas sugedo',

        feature_request: 'Funkcijos užklausa',
        feature_request_help: 'Kaip galime tobulėti',

        contact_us: 'Susisiekite su mumis',
        contact_us_help: 'Susisiekite su mumis',

        view_other_feedback: 'Peržiūrėti kitą atsiliepimą',
        view_other_feedback_help: 'Peržiūrėkite, kas jau buvo pateikta',

        your_name: 'Tavo vardas',
        your_email_address: 'Jūsų el. pašto adresas',
        select_a_category: 'Pasirinkite kategoriją',
        select_a_priority: 'Pasirinkite prioritetą',
        assign_to: 'Priskirti',
        leave_us_your_comment: 'Palikite mums savo komentarą...',
        attach_a_screenshot : 'pridėkite ekrano kopiją',
        screenshot_attached: 'ekrano kopija',

        send:    'Siųsti',
        back:    'Atgal',
        remove:  'pašalinti',
        close:   'Uždaryti',
        next:    'Kitas',
        send_success: 'Ačiū už jūsų atsiliepimus!',

        save:   'Išsaugoti',
        delete: 'Ištrinti',
        add_your_comment_here: 'Pridėkite komentarą čia...',

        attach_a_file: 'Pridėkite failą',
        file_format_not_supported: 'Failo tipas nepalaikomas',
        max: 'Maksimalus',

        open_feedback: 'atidaryti atsiliepimą',
        search: 'Paieška...',
        cancel: 'Atšaukti',
        feedback_title: 'Pridėti pavadinimą',
        feedback_not_found: 'Oi, nerasta atsiliepimų pagal kriterijus',
        feedback_type_all: 'Viskas',
        feedback_type_general: 'Bendras atsiliepimas',
        feedback_type_bug: 'Pranešimas apie klaidas',
        feedback_type_feature_request: 'Funkcijos užklausa',
        feedback_type_screenshot: 'Ekrano kopija',
        feedback_type_video: 'Ekrano įrašymas',
        this_page_only: 'Tik šis puslapis',

        want_to_leave: 'Yra neišsaugotų pakeitimų',
        changes_not_saved: 'Ar tikrai norite uždaryti?',
        discard: 'Išmesti',

        start_recording: 'Pradėkite įrašymą',

        recording_mute: 'Išjunkite mikrofoną',
        recording_unmute: 'Įjunkite mikrofoną',
        recording_pause: 'Pauzė',
        recording_resume: 'Tęsti',
        recording_draw: 'Lygiosios',
        recording_finish: 'Baigti įrašymą'
    },

    // Polish
    'pl': {
        draw_on_the_screen: 'Wskaż na ekranie',
        draw_on_the_screen_help: 'Prześlij sugestie oznaczając obszar na ekranie',

        general_feedback: 'Sugestia ogólna',
        general_feedback_help: 'Prześlij ogólną sugestie dotyczącą strony',

        capture_video: 'Ekran nagrywania',
        capture_video_help: 'Prześlij opinię z zapisem ekranu',

        report_bug: 'Zgłoś błąd',
        report_bug_help: 'Daj nam znać, co się zepsuło',

        feature_request: 'Prośba o funkcję',
        feature_request_help: 'Powiedz nam, jak możemy się poprawić',

        contact_us: 'Skontaktuj się z nami',
        contact_us_help: 'Skontaktuj się z nami',

        view_other_feedback: 'Zobacz inne sugestie',
        view_other_feedback_help: 'Zobacz, co już zostało przesłane',

        your_name: 'Twoje imię',
        your_email_address: 'Twój adres Email',
        select_a_category: 'Wybierz kategorie',
        select_a_priority: 'Wybierz priorytet',
        assign_to: 'Przypisać do',
        leave_us_your_comment: 'Pozostaw swój komentarz...',
        attach_a_screenshot : 'Załącz zrzut ekranu',
        screenshot_attached: 'zrzut ekranu',

        send:    'Wyślij',
        back:    'wróć',
        remove:  'Usunąć',
        close:   'Zamknij',
        next:    'Dalej',
        send_success: 'Dziekuję za odpowiedź!',

        save:   'Zapisz',
        delete: 'Usuń',
        add_your_comment_here: 'Dodaj swój komentarz...',

        attach_a_file: 'Dodaj plik',
        file_format_not_supported: 'Typ pliku nie jest obsługiwany',
        max: 'Max',

        open_feedback: 'otwartych sugestii',
        search: 'Search...',
        cancel: 'Anuluj',
        feedback_title: 'Dodaj tytuł',
        feedback_not_found: 'Ups, nie znaleziono pasujących sugestii',
        feedback_type_all: 'Wszystko',
        feedback_type_general: 'Sugestia ogólna',
        feedback_type_bug: 'Zgłoszenie błędu',
        feedback_type_feature_request: 'Prośba o funkcję',
        feedback_type_screenshot: 'Zrzut ekranu',
        feedback_type_video: 'Nagrywanie ekranu',
        this_page_only: 'Tylko ta strona',

        want_to_leave: 'Masz niezapisane zmiany',
        changes_not_saved: 'Na pewno chcesz zamknąć to okno?',
        discard: 'Odrzuć',

        start_recording: 'Początek',

        recording_mute: 'Wyłącz mikrofon',
        recording_unmute: 'Włącz mikrofon',
        recording_pause: 'Pauza',
        recording_resume: 'Wznów',
        recording_draw: 'Remis',
        recording_finish: 'Zakończ nagrywanie'
    },

    // German
    'de': {
        draw_on_the_screen: 'Auf den Bildschirm zeichnen',
        draw_on_the_screen_help: 'Feedback zu bestimmten Bereichen dieser Seite geben',

        general_feedback: 'Allgemeines Feedback',
        general_feedback_help: 'Allgemeines Feedback zu dieser Seite geben',

        report_bug: 'Melde einen technischen Fehler',
        report_bug_help: 'Lass uns wissen was kaputt ist',

        feature_request: 'Featureanfrage',
        feature_request_help: 'Sagen Sie uns, wie wir uns verbessern können',

        capture_video: 'Aufnahmebildschirm',
        capture_video_help: 'Geben Sie Feedback mit Bildschirmaufzeichnung',

        contact_us: 'Kontaktiere uns',
        contact_us_help: 'Nehmen Sie Kontakt mit uns auf',

        view_other_feedback: 'Weiteres Feedback ansehen',
        view_other_feedback_help: 'Sehen Sie, was bereits eingereicht wurde',

        your_name: 'Dein Name',
        your_email_address: 'Deine Email Adresse',
        select_a_category: 'Eine Kategorie auswählen',
        select_a_priority: 'Wählen Sie die Priorität',
        assign_to: 'Zuständiger',
        leave_us_your_comment: 'Bitte geben Sie hier ihr Feedback ein...',
        attach_a_screenshot : 'Einen Screenshot mitsenden',
        screenshot_attached: 'screenshot',

        send:    'Absenden',
        back:    'Zurück',
        remove:  'Entfernen',
        close:   'Schließen',
        next:    'Weiter',
        send_success: 'Vielen Dank für Ihr Feedback!',

        save:   'Speichern',
        delete: 'Löschen',
        add_your_comment_here: 'Bitte geben Sie hier Ihr Feedback ein…',

        attach_a_file: 'Eine Datei anhängen',
        file_format_not_supported: 'Dateiformat wird nicht unterstützt',
        max: 'Max',

        open_feedback: 'Feedbacks',
        search: 'Suchen...',
        cancel: 'Abbrechen',
        feedback_title: 'Titel hinzufügen',
        feedback_not_found: 'Ups, es gibt kein Feedback zu Ihrer Suche.',
        feedback_type_all: 'Alle',
        feedback_type_general: 'Allgemeines Feedback',
        feedback_type_bug: 'Fehlerbericht',
        feedback_type_feature_request: 'Featureanfrage',
        feedback_type_screenshot: 'Bildschirmfoto',
        feedback_type_video: 'Bildschirmaufnahme',
        this_page_only: 'Nur diese Seite',

        want_to_leave: 'Die Änderungen wurden noch nicht gespeichert',
        changes_not_saved: 'Möchten Sie den Editor wirklich schließen?',
        discard: 'Verwerfen',

        start_recording: 'Start',

        recording_mute: 'Mikrofon ausschalten',
        recording_unmute: 'Mikrofon einschalten',
        recording_pause: 'Pause',
        recording_resume: 'Fortsetzen',
        recording_draw: 'Zeichnen',
        recording_finish: 'Beenden Sie die Aufnahme'
    },

    // Danish
    'da': {
        draw_on_the_screen: 'Tegn på skærmen',
        draw_on_the_screen_help: 'Giv feedback til et område på siden',

        general_feedback: 'Giv feedback',
        general_feedback_help: 'Giv feedback til denne side',

        capture_video: 'Optageskærm',
        capture_video_help: 'Giv feedback med skærmoptegnelse',

        report_bug: 'Anmeld en fejl',
        report_bug_help: 'Fortæl os, hvad der er gået i stykker',

        feature_request: 'Funktionsanmodning',
        feature_request_help: 'Fortæl os, hvordan vi kan forbedre os',

        contact_us: 'Kontakt os',
        contact_us_help: 'kom i kontakt med os',

        view_other_feedback: 'Se andres feedback',
        view_other_feedback_help: 'Se hvad der allerede er sendt',

        your_name: 'Dit navn',
        your_email_address: 'Din email',
        select_a_category: 'Vælg en kategori',
        select_a_priority: 'Vælg prioritet',
        assign_to: 'Tildel til',
        leave_us_your_comment: 'Skriv en kommentar...',
        attach_a_screenshot : 'Vedhæft et skærmbillede',
        screenshot_attached: 'skærmbillede',

        send:    'Send',
        back:    'Tilbage',
        remove:  'Fjerne',
        close:   'Luk',
        next:    'Næste',
        send_success: 'Tak for din tilbagemelding!',

        save:   'Gem',
        delete: 'Slet',
        add_your_comment_here: 'Tilføj din kommentar her...',

        attach_a_file: 'Vedhæft en fil',
        file_format_not_supported: 'Filtypen understøttes ikke',
        max: 'Maks',

        open_feedback: 'feedback',
        search: 'Søg...',
        cancel: 'Afbryd',
        feedback_title: 'Tilføj en titel',
        feedback_not_found: 'Ups, kan ikke finde matchende feedback',
        feedback_type_all: 'Alle',
        feedback_type_general: 'Giv feedback',
        feedback_type_bug: 'Fejlrapport',
        feedback_type_feature_request: 'Funktionsanmodning',
        feedback_type_screenshot: 'Skærmbillede',
        feedback_type_video: 'Skærmoptagelse',
        this_page_only: 'Denne side kun',

        want_to_leave: 'Der er ændringer, som ikke er gemt',
        changes_not_saved: 'Er du sikker på, at du vil lukke?',
        discard: 'Kassér',

        start_recording: 'Start',

        recording_mute: 'Sluk for mikrofonen',
        recording_unmute: 'Tænd mikrofonen',
        recording_pause: 'Pause',
        recording_resume: 'Genoptag',
        recording_draw: 'Tegne',
        recording_finish: 'Afslut optagelsen'
    },

    // Spanish
    'es': {
        draw_on_the_screen: 'Dibujar en la pantalla',
        draw_on_the_screen_help: 'Proporcionar comentarios de áreas específicas de esta página',

        general_feedback: 'Comentario General',
        general_feedback_help: 'Proporcionar comentarios generales de esta página',

        capture_video: 'Pantalla de grabación',
        capture_video_help: 'Proporcione comentarios con el registro de pantalla',

        report_bug: 'Reportar un error',
        report_bug_help: 'Háganos saber lo que está roto',

        feature_request: 'Solicitud de función',
        feature_request_help: 'Díganos como podemos mejorar',

        contact_us: 'Contáctenos',
        contact_us_help: 'Póngase en contacto con nosotros',

        view_other_feedback: 'Ver otros comentarios',
        view_other_feedback_help: 'Ver lo que ya se ha enviado',

        your_name: 'Tu nombre',
        your_email_address: 'Tu Correo Electrónico',
        select_a_category: 'Elige la Categoría',
        select_a_priority: 'Elige la Prioridad',
        assign_to: 'Cesionario',
        leave_us_your_comment: 'Dejanos tu comentario...',
        attach_a_screenshot : 'adjunta una captura de pantalla',
        screenshot_attached: 'captura de pantalla',

        send:    'Enviar',
        back:    'Atrás',
        remove:  'Eliminar',
        close:   'Cerrar',
        next:    'Siguiente',
        send_success: 'Gracias por sus comentarios!',

        save:   'Guardar',
        delete: 'Borrar',
        add_your_comment_here: 'Agrega tu comentario aquí...',

        attach_a_file: 'Adjuntar un archivo',
        file_format_not_supported: 'Formato de archivo no soportado',
        max: 'Máx',

        open_feedback: 'Comentarios abiertos',
        search: 'Buscar...',
        cancel: 'Cancelar',
        feedback_title: 'Agregar un título',
        feedback_not_found: 'Vaya, no se pudo encontrar ningún comentario coincidente.',
        feedback_type_all: 'Todas',
        feedback_type_general: 'Comentario General',
        feedback_type_bug: 'Informe de error',
        feedback_type_feature_request: 'Solicitud de función',
        feedback_type_screenshot: 'Captura de pantalla',
        feedback_type_video: 'Grabación de pantalla',
        this_page_only: 'Esta página solamente',

        want_to_leave: 'You have unsaved changes',
        changes_not_saved: 'Are you sure that you want to close?',
        discard: 'Discard',

        start_recording: 'Comienzo',

        recording_mute: 'Apagar el micrófono',
        recording_unmute: 'Enciende el micrófono',
        recording_pause: 'Pausa',
        recording_resume: 'Reanudar',
        recording_draw: 'Dibujar',
        recording_finish: 'Reanudar grabación'
    },

    // Slovak
    'sk': {
        draw_on_the_screen: 'Nakresliť na obrazovku',
        draw_on_the_screen_help: 'Poskytnite spätnú väzbu konkrétnych oblastí na tejto stránke',

        general_feedback: 'Všeobecná Spätná Väzba',
        general_feedback_help: 'Poskytnite všeobecnú spätnú väzbu tejto stránky',

        capture_video: 'Obrazovka nahrávania',
        capture_video_help: 'Poskytnite spätnú väzbu pomocou záznamu obrazovky',

        report_bug: 'Nahlásiť chybu',
        report_bug_help: 'Dajte nám vedieť, čo sa pokazilo',

        feature_request: 'Požiadavka funkcie',
        feature_request_help: 'Povedzte nám, ako sa môžeme zlepšiť',

        contact_us: 'Kontaktuj nás',
        contact_us_help: 'Spojte sa s nami',

        view_other_feedback: 'Zobraziť inú spätnú väzbu',
        view_other_feedback_help: 'Pozrite sa, čo už bolo odoslané',

        your_name: 'Tvoje meno',
        your_email_address: 'Tvoja emailova adresa',
        select_a_category: 'Zvoliť kategóriu',
        select_a_priority: 'Vyberte prioritu',
        assign_to: 'Priradiť',
        leave_us_your_comment: 'Zanechajte nám svoj komentár...',
        attach_a_screenshot : 'pripojiť snímku obrazovky',
        screenshot_attached: 'screenshot',

        send:    'Poslať',
        back:    'Späť',
        remove:  'Odstrániť',
        close:   'Zatvorte',
        next:    'Ďalej',
        send_success: 'Ďakujem za spätnú väzbu!',

        save:   'Uložiť',
        delete: 'Zmazať',
        add_your_comment_here: 'Pridajte svoj komentár tu...',

        attach_a_file: 'Priložiť Súbor',
        file_format_not_supported: 'Formát súboru nieje podporovaní',
        max: 'Najviac',

        open_feedback: 'otvorených spätných väzieb',
        search: 'Vyhľadávanie...',
        cancel: 'Zrušiť',
        feedback_title: 'Pridať názov',
        feedback_not_found: 'Ups, žiadne zhodujúce spätné väzby neboli nájdené.',
        feedback_type_all: 'Všetky',
        feedback_type_general: 'Všeobecná Spätná Väzba',
        feedback_type_bug: 'Hlásenie o chybe',
        feedback_type_feature_request: 'Požiadavka funkcie',
        feedback_type_screenshot: 'Screenshot',
        feedback_type_video: 'Záznam obrazovky',
        this_page_only: 'Iba táto stránka',

        want_to_leave: 'Existujú neuložené zmeny',
        changes_not_saved: 'Naozaj chcete editor zavrieť?',
        discard: 'Zahodiť',

        start_recording: 'štart',

        recording_mute: 'Vypnite mikrofón',
        recording_unmute: 'Zapnite mikrofón',
        recording_pause: 'Pauza',
        recording_resume: 'ďalej',
        recording_draw: 'Nakreslite',
        recording_finish: 'Dokončite nahrávanie'
    },

    // Slovenian
    'sl': {
        draw_on_the_screen: 'Risanje na zaslon',
        draw_on_the_screen_help: 'Nariši svoj odziv na zaslon',

        capture_video: 'Zajem videa',
        capture_video_help: 'Zabeleži svoj odziv s video posnetkom',

        report_bug: 'Prijavite napako',
        report_bug_help: 'Sporočite nam, kaj je pokvarjeno',

        feature_request: 'Zahteva za funkcijo',
        feature_request_help: 'Povejte nam, kako se lahko izboljšamo',

        general_feedback: 'Splošne povratne informacije',
        general_feedback_help: 'Dodaj splošne povratne informacije o tej strani',

        contact_us: 'Kontaktiraj nas',
        contact_us_help: 'Stopite z nami v stik',

        view_other_feedback: 'Oglej si preostale odzive"',
        view_other_feedback_help: 'Oglejte si, kaj je že bilo poslano',

        your_name: 'Ime',
        your_email_address: 'Email naslov',
        select_a_category: 'Izberi kategorijo',
        select_a_priority: 'Izberite prednost',
        assign_to: 'Dodelite',
        leave_us_your_comment: 'Zabeleži komentar...',
        attach_a_screenshot: 'Dodaj posnetek zaslona',
        screenshot_attached: 'screenshot',

        send:    'Pošlji',
        back:    'nazaj',
        remove:  'Odstrani',
        close:   'Zapri',
        next:    'Naprej',
        send_success: 'Hvala za odziv!',

        save:   'Shrani',
        delete: 'izbriši',
        add_your_comment_here: 'Vnesi komentar...',

        attach_a_file: 'Pripni datoteko',
        file_format_not_supported: 'Format datoteke ni podprt',
        max: 'Max',

        open_feedback: 'odziv',
        search: 'Išči...',
        cancel: 'Prekliči',
        feedback_title: 'Dodaj naslov',
        feedback_not_found: 'Ups, noben odziv ni bil zabeležen.',
        feedback_type_all: 'Vse',
        feedback_type_general: 'Splošne povratne informacije',
        feedback_type_bug: 'Poročilo o napaki',
        feedback_type_feature_request: 'Zahteva za funkcijo',
        feedback_type_screenshot: 'Posnetek zaslona',
        feedback_type_video: 'Snemanje zaslona',
        this_page_only: 'Samo na tej strani',

        want_to_leave: 'Imate neshranjene spremembe',
        changes_not_saved: 'Ali ste prepričani, da želite zapreti to okno?',
        discard: 'Zavrzi',

        start_recording: 'Prični s snemanjem',

        recording_mute: 'Izklopite mikrofon',
        recording_unmute: 'Vklopite mikrofon',
        recording_pause: 'Pavza',
        recording_resume: 'Nadaljuj',
        recording_draw: 'Nariši',
        recording_finish: 'Končaj snemanje',

        video_not_supported: 'Snemanje videa ni podprto',
        video_requires_https: 'Za snemanje videa je potrebna varna povezava HTTPS.',
        ok: 'OK'
    },

    // Italian
    'it': {
        draw_on_the_screen: 'Disegna sullo Schermo',
        draw_on_the_screen_help: 'Invia un feedback per una specifica area di questa pagina',

        general_feedback: 'Feedback Generale',
        general_feedback_help: 'Invia un feedback generale per questa pagina',

        capture_video: 'Schermata di registrazione',
        capture_video_help: 'Fornire feedback con la registrazione dello schermo',

        report_bug: 'Segnalare un bug',
        report_bug_help: 'Facci sapere cosa è rotto',

        feature_request: 'Richiesta di funzionalità',
        feature_request_help: 'Dicci come possiamo migliorare',

        contact_us: 'Contattaci',
        contact_us_help: 'Mettetevi in ​​contatto con noi',

        view_other_feedback: 'Visualizza altri Feedback',
        view_other_feedback_help: 'Guarda cosa è già stato inviato',

        your_name: 'Il tuo nome',
        your_email_address: 'Il tuo Indirizzo Email',
        select_a_category: 'Seleziona una Categoria',
        select_a_priority: 'Scegli la priorità',
        assign_to: 'Assegnato',
        leave_us_your_comment: 'Lasciaci un commento...',
        attach_a_screenshot : 'Allegare uno screenshot',
        screenshot_attached: 'screenshot',

        send:    'Invia',
        back:    'indietro',
        remove:  'Rimuovere',
        close:   'Chiudi',
        next:    'Avanti',
        send_success: 'Grazie per il tuo feedback!',

        save:   'Salva',
        delete: 'cancella',
        add_your_comment_here: 'Aggiungi un commento qui...',

        attach_a_file: 'Allega un File',
        file_format_not_supported: 'Formato file non supportato',
        max: 'Max',

        open_feedback: 'feedback aperti',
        search: 'Cerca...',
        cancel: 'Cancella',
        feedback_title: 'Aggiungi un titolo',
        feedback_not_found: 'Ops, nessun feedback trovato.',
        feedback_type_all: 'Tutte',
        feedback_type_general: 'Feedback Generale',
        feedback_type_bug: 'Riportare un errore',
        feedback_type_feature_request: 'Richiesta di funzionalità',
        feedback_type_screenshot: 'Immagine dello schermo',
        feedback_type_video: 'Registrazione dello schermo',
        this_page_only: 'Solo questa pagina',

        want_to_leave: 'Sono presenti modifiche non salvate',
        changes_not_saved: 'Chiudere?',
        discard: 'Annulla',

        start_recording: 'Inizio',

        recording_mute: 'Disattiva il microfono',
        recording_unmute: 'Attiva il microfono',
        recording_pause: 'Pausa',
        recording_resume: 'Riprendi',
        recording_draw: 'Disegnare',
        recording_finish: 'Termina la registrazione'
    },

    // Romanian
    'ro': {
        draw_on_the_screen: 'Marcheaza pe ecran',
        draw_on_the_screen_help: 'Ofera-ne feedback pentru anumite sectiuni din pagina',

        general_feedback: 'Feedback General',
        general_feedback_help: 'Ofera-ne feedback general despre aceasta pagina',

        capture_video: 'Ecran de înregistrare',
        capture_video_help: 'Oferiți feedback cu înregistrarea ecranului',

        report_bug: 'Raporteaza o eroare',
        report_bug_help: 'Spune-ne ce s-a stricat',

        feature_request: 'Cerere de caracteristici',
        feature_request_help: 'Spune-ne cum ne putem îmbunătăți',

        contact_us: 'Contacteaza-ne',
        contact_us_help: 'Contactează-ne',

        view_other_feedback: 'Vezi feedack-ul anterior',
        view_other_feedback_help: 'Vedeți ce a fost deja trimis',

        your_name: 'Numele dumneavoastră',
        your_email_address: 'Adresa ta de email',
        select_a_category: 'Alegeti o categorie',
        select_a_priority: 'Alegeți prioritatea',
        assign_to: 'Atribuie la',
        leave_us_your_comment: 'Lasa-ti-ne un sfat...',
        attach_a_screenshot : 'Atașați o captură de ecran',
        screenshot_attached: 'captură de ecran',

        send:    'Trimite',
        back:    'Inapoi',
        remove:  'Elimina',
        close:   'Închideți',
        next:    'Înainte',
        send_success: 'Vă mulțumim pentru feedback-ul dumneavoastră!',

        save:   'Salveaza',
        delete: 'sterge',
        add_your_comment_here: 'Adauga comentariul tau aici...',

        attach_a_file: 'Atasati fisiere',
        file_format_not_supported: 'Tipul fisierului nu este suportat',
        max: 'Maxim',

        open_feedback: 'feedback deschis',
        search: 'Cauta...',
        cancel: 'Renunt',
        feedback_title: 'Adaugă un titlu',
        feedback_not_found: 'Ups, nu am gasit feedback pe baza cautarii.',
        feedback_type_all: 'Toate',
        feedback_type_general: 'Feedback General',
        feedback_type_bug: 'Raport de eroare',
        feedback_type_feature_request: 'Cerere de caracteristici',
        feedback_type_screenshot: 'Captură de ecran',
        feedback_type_video: 'Înregistrare ecran',
        this_page_only: 'Numai această pagină',

        want_to_leave: 'Ai modificări nesalvate',
        changes_not_saved: 'Sigur dorești să închizi?',
        discard: 'Șterge',

        start_recording: 'Start',

        recording_mute: 'Opriți microfonul',
        recording_unmute: 'Porniți microfonul',
        recording_pause: 'Pauză',
        recording_resume: 'Reluați',
        recording_draw: 'A desena',
        recording_finish: 'Finalizați înregistrarea'
    },

    // Japanese
    'jp': {
        draw_on_the_screen: '画面上に描く',
        draw_on_the_screen_help: '画面のスクリーンショットを利用して、フィードバックを行う',

        general_feedback: '一般的なフィードバック',
        general_feedback_help: 'このページに関して一般的なフィードバックを行う',

        capture_video: '記録画面',
        capture_video_help: '画面記録でフィードバックを提供する',

        report_bug: 'バグを報告',
        report_bug_help: '何が壊れているか教えてください',

        feature_request: '機能リクエスト',
        feature_request_help: '改善方法を教えてください',

        contact_us: 'お問い合わせ',
        contact_us_help: 'ご不明な点やご質問などはいつでも承りますので、どうぞお気軽にご相談ください',

        view_other_feedback: '他のフィードバックを見る',
        view_other_feedback_help: 'すでに提出されたものを見る',

        your_name: 'あなたの名前',
        your_email_address: 'ご連絡先Eメールアドレス',
        select_a_category: 'カテゴリーを選ぶ',
        select_a_priority: '優先順位を選択',
        assign_to: '割りあてる',
        leave_us_your_comment: 'お問い合わせ内容...',
        attach_a_screenshot: 'スクリーンショットを添付する',
        screenshot_attached: 'screenshot',

        send:    '送信する',
        back:    '戻る',
        remove:  '削除する',
        close:   '閉じる',
        next:    '次へ',
        send_success: 'お問い合わせありがとうございます',

        save:   '保存する',
        delete: '削除する',
        add_your_comment_here: 'お問い合わせ内容...',

        attach_a_file: 'ファイルを添付する',
        file_format_not_supported: 'ファイル形式はサポートされていません',
        max: '最大',

        open_feedback: 'なフィードバック',
        search: '検索...',
        cancel: 'キャンセル',
        feedback_title: 'タイトルを追加',
        feedback_not_found: '見つかりません',
        feedback_type_all: 'すべて',
        feedback_type_general: '一般的なフィードバック',
        feedback_type_bug: 'バグレポート',
        feedback_type_feature_request: '機能リクエスト',
        feedback_type_screenshot: 'スクリーンショット',
        feedback_type_video: 'スクリーンレコーディング',
        this_page_only: 'このページのみ',

        want_to_leave: '保存されていない変更があります',
        changes_not_saved: '閉じてもよろしいですか？',
        discard: '破棄',

        start_recording: '開始',

        recording_mute: 'マイクをオフにする',
        recording_unmute: 'マイクをオンにする',
        recording_pause: '一時停止',
        recording_resume: '再開',
        recording_draw: 'ドロー',
        recording_finish: '録音を終了'
    },

    // Norwegian
    'no': {
        draw_on_the_screen: 'Tegn på skjermen',
        draw_on_the_screen_help: 'Gi tilbakemelding på spesifikke områder på denne siden',

        general_feedback: 'Generell tilbakemelding',
        general_feedback_help: 'Gi generell tilbakemelding på denne siden',

        capture_video: 'Innspillingsskjerm',
        capture_video_help: 'Gi tilbakemelding med skjermoppføring',

        report_bug: 'Rapporter en feil',
        report_bug_help: 'Fortell oss hva som er ødelagt',

        feature_request: 'Funksjonsforespørsel',
        feature_request_help: 'Fortell oss hvordan vi kan forbedre oss',

        contact_us: 'Kontakt oss',
        contact_us_help: 'Ta kontakt med oss',

        view_other_feedback: 'Se andre tilbakemeldinger',
        view_other_feedback_help: 'Se hva som allerede er sendt inn',

        your_name: 'Navnet ditt',
        your_email_address: 'Din e-postadresse',
        select_a_category: 'Velg en kategori',
        select_a_priority: 'Velg prioritet',
        assign_to: 'Tildel til',
        leave_us_your_comment: 'Legg igjen din kommentar...',
        attach_a_screenshot: 'legg til et skjermbilde',
        screenshot_attached: 'screenshot',

        send:    'Send',
        back:    'tilbake',
        remove:  'Fjerne',
        close:   'Lukk',
        next:    'Neste',
        send_success: 'Takk for tilbakemeldingen!',

        save:   'Lagre',
        delete: 'Slett',
        add_your_comment_here: 'Legg til din kommentar her...',

        attach_a_file: 'Legg ved en fil',
        file_format_not_supported: 'Filformatet støttes ikke',
        max: 'Maks',

        open_feedback: 'åpne tilbakemeldinger',
        search: 'Søk',
        cancel: 'Avbryt',
        feedback_title: 'Legg til en tittel',
        feedback_not_found: 'Ups, ingen tilsvarende tilbakemeldinger ble funnet',
        feedback_type_all: 'Alle',
        feedback_type_general: 'Generell tilbakemelding',
        feedback_type_bug: 'Feilrapport',
        feedback_type_feature_request: 'Funksjonsforespørsel',
        feedback_type_screenshot: 'Skjermbilde',
        feedback_type_video: 'Skjermopptak',
        this_page_only: 'Bare denne siden',

        want_to_leave: 'Du har ulagrede endringer',
        changes_not_saved: 'Er du sikker på at du vil lukke redigeringsvinduet?',
        discard: 'Forkast',

        start_recording: 'Start',

        recording_mute: 'Slå av mikrofonen',
        recording_unmute: 'Slå på mikrofonen',
        recording_pause: 'Pause',
        recording_resume: 'Fortsett',
        recording_draw: 'Tegne',
        recording_finish: 'Fullfør innspillingen'
    },

    // Estonia
    'et': {
        draw_on_the_screen: 'Joonista ekraanil',
        draw_on_the_screen_help: 'Andke tagasisidet selle lehe teatud piirkondade kohta',

        general_feedback: 'Üldine tagasiside',
        general_feedback_help: 'Esitage selle lehe üldine tagasiside',

        capture_video: 'Salvestuskuva',
        capture_video_help: 'Andke tagasisidet ekraanipilti',

        report_bug: 'Teata veast',
        report_bug_help: 'Andke meile teada, mis on katki',

        feature_request: 'Funktsiooni taotlus',
        feature_request_help: 'Rääkige meile, kuidas saaksime seda parandada',

        contact_us: 'Võta meiega ühendust',
        contact_us_help: 'Võta meiega ühendust',

        view_other_feedback: 'Vaata kogu tagasisidet',
        view_other_feedback_help: 'Vaadake, mis on juba esitatud',

        your_name: 'Sinu nimi',
        your_email_address: 'Teie e-posti aadress',
        select_a_category: 'Valige kategooria',
        select_a_priority: 'Valige prioriteet',
        assign_to: 'Määrata',
        leave_us_your_comment: 'Jäta meile oma kommentaar...',
        attach_a_screenshot: 'lisage ekraanipilt',
        screenshot_attached: 'screenshot',

        send:    'Saada',
        back:    'tagasi',
        remove:  'Eemalda',
        close:   'Sulge',
        next:    'Edasi',
        send_success: 'Täname tagasiside eest',

        save:   'Salvesta',
        delete: 'Kustuta',
        add_your_comment_here: 'Lisage siia oma kommentaar...',

        attach_a_file: 'Faili lisamine',
        file_format_not_supported: 'Failivormingut ei toetata',
        max: 'Maks',

        open_feedback: 'avatud tagasiside',
        search: 'Otsi',
        cancel: 'Tühista',
        feedback_title: 'Lisa pealkiri',
        feedback_not_found: 'Vabandust, vastavat tagasisidet ei leitud',
        feedback_type_all: 'Kõik',
        feedback_type_general: 'Üldine tagasiside',
        feedback_type_bug: 'Veateade',
        feedback_type_feature_request: 'Funktsiooni taotlus',
        feedback_type_screenshot: 'Ekraanipilt',
        feedback_type_video: 'Ekraani salvestamine',
        this_page_only: 'Ainult see leht',

        want_to_leave: 'Teil on salvestamata muudatusi',
        changes_not_saved: 'Kas olete kindel, et soovite sulgeda?',
        discard: 'Loobu',

        start_recording: 'Start',

        recording_mute: 'Lülitage mikrofon välja',
        recording_unmute: 'Lülitage mikrofon sisse',
        recording_pause: 'Paus',
        recording_resume: 'Jätka',
        recording_draw: 'Joonista',
        recording_finish: 'Salvestamise lõpetamine'
    },

    // Svenska (Swedish)
    'sv': {
        draw_on_the_screen: 'Rita på skärmen',
        draw_on_the_screen_help: 'Ange feedback tillsammans med en skärmdump',

        general_feedback: 'Allmän Feedback',
        general_feedback_help: 'Ange generell feedback för denna sidan',

        capture_video: 'Spela in skärmen',
        capture_video_help: 'Ge feedback med skärmdata',

        report_bug: 'Rapportera en bugg',
        report_bug_help: 'Låt oss veta vad som är trasigt',

        feature_request: 'Funktionsbegäran',
        feature_request_help: 'Berätta för oss hur vi kan förbättra oss',

        contact_us: 'Kontakta oss',
        contact_us_help: 'Kom i kontakt med oss',

        view_other_feedback: 'Visa annan feedback',
        view_other_feedback_help: 'Se vad som redan har skickats',

        your_name: 'Ditt namn',
        your_email_address: 'Din e-postadress',
        select_a_category: 'Välj en kategori',
        select_a_priority: 'Välj prioritet',
        assign_to: 'Tilldelad',
        leave_us_your_comment: 'Lämna en kommentar...',
        attach_a_screenshot: 'Lämna en skärmdump',
        screenshot_attached: 'screenshot',

        send:    'Skicka',
        close:   'Stänga',
        next:    'Nästa',
        send_success: 'Tack för feedbacken!',

        save:   'Spara',
        delete: 'Radera',
        add_your_comment_here: 'Ange din kommentar här...',

        attach_a_file: 'Bifoga en fil',
        file_format_not_supported: 'Filformat stöds inte',
        max: 'Max',

        open_feedback: 'feedback',
        search: 'Sök...',
        cancel: 'Avbryt',
        feedback_title: 'Lägg till en titel',
        feedback_not_found: 'Oops, ingen matchande feedback kunde hittas.',
        feedback_type_all: 'Allt',
        feedback_type_general: 'Allmän Feedback',
        feedback_type_bug: 'Buggrapport',
        feedback_type_feature_request: 'Funktionsbegäran',
        feedback_type_screenshot: 'Skärmdump',
        feedback_type_video: 'Skärminspelning',
        this_page_only: 'Endast denna sida',

        want_to_leave: 'Du har ändringar som inte är sparade',
        changes_not_saved: 'Är du säker på att du vill stänga?',
        discard: 'Släng',

        start_recording: 'Start',

        recording_mute: 'Stäng av mikrofonen',
        recording_unmute: 'Slå på mikrofonen',
        recording_pause: 'Paus',
        recording_resume: 'Återuppta',
        recording_draw: 'Dra',
        recording_finish: 'Slutför inspelningen'
    },

    // Suomi (Finnish)
    'fi': {
        draw_on_the_screen: 'Piirrä näytölle',
        draw_on_the_screen_help: 'Anna palautetta tietyistä tämän sivun alueista',

        general_feedback: 'Yleinen palaute',
        general_feedback_help: 'Anna yleistä palautetta tästä sivusta',

        capture_video: 'Nauhoitusnäyttö',
        capture_video_help: 'Anna palautetta näytöllä',

        report_bug: 'Ilmoita virheestä',
        report_bug_help: 'Kerro meille, mikä on rikki',

        feature_request: 'Ominaisuuspyyntö',
        feature_request_help: 'Kerro meille, kuinka voimme parantaa',

        contact_us: 'Ota meihin yhteyttä',
        contact_us_help: 'Ota meihin yhteyttä',

        view_other_feedback: 'Näytä palautehistoria',
        view_other_feedback_help: 'Katso, mitä on jo lähetetty',

        your_name: 'Nimesi',
        your_email_address: 'Sähköpostiosoitteesi',
        select_a_category: 'Valitse kategoria',
        select_a_priority: 'Valitse prioriteetti',
        assign_to: 'Määritä',
        leave_us_your_comment: 'Jätä meille kommentti...',
        attach_a_screenshot: 'liitä kuvakaappaus',
        screenshot_attached: 'screenshot',

        send:    'Lähetä',
        close:   'Sulje',
        next:    'Seuraava',
        send_success: 'Kiitos palautteestasi!',

        save:   'Tallenna',
        delete: 'Poista',
        add_your_comment_here: 'Kirjoita palautteesi tähän...',

        attach_a_file: 'Liitä tiedosto',
        file_format_not_supported: 'Tiedostoformaatti ei ole tuettu',
        max: 'Maks',

        open_feedback: 'avointa palautetta',
        search: 'Etsi...',
        cancel: 'Peruuta',
        feedback_title: 'Lisää otsikko',
        feedback_not_found: 'Ups, yhtään vastaavaa palautetta ei löytynyt.',
        feedback_type_all: 'Kaikki',
        feedback_type_general: 'Yleinen palaute',
        feedback_type_bug: 'Virhe raportti',
        feedback_type_feature_request: 'Ominaisuuspyyntö',
        feedback_type_screenshot: 'Näyttökuva',
        feedback_type_video: 'Näytön tallennus',
        this_page_only: 'Vain tämä sivu',

        want_to_leave: 'Joitakin muutoksia ei ole tallennettu',
        changes_not_saved: 'Haluatko varmasti sulkea?',
        discard: 'Hylkää',

        start_recording: 'Alkaa',

        recording_mute: 'Sammuta mikrofoni',
        recording_unmute: 'Kytke mikrofoni päälle',
        recording_pause: 'Tauko',
        recording_resume: 'Jatka',
        recording_draw: 'Piirrä',
        recording_finish: 'Lopeta nauhoitus'
    },

    // magyar (Hungarian)
    'hu': {
        draw_on_the_screen: 'Rajzolj a képernyőre',
        draw_on_the_screen_help: 'Adj visszajelzést az oldal bizonyos területeire',

        general_feedback: 'Általános visszajelzés',
        general_feedback_help: 'Adj általános visszajelzést az oldalról',

        capture_video: 'Felvétel képernyő',
        capture_video_help: 'Visszajelzést adhat a képernyőfelvételről',

        report_bug: 'Hiba bejelentése',
        report_bug_help: 'Tudasd velünk, mi tört el',

        feature_request: 'Funkciókérés',
        feature_request_help: 'Mondja el, hogyan javíthatunk',

        contact_us: 'Lépjen kapcsolatba velünk',
        contact_us_help: 'Lépjen kapcsolatba velünk',

        view_other_feedback: 'Visszajelzések megtekintése',
        view_other_feedback_help: 'Nézze meg, hogy mi van már beküldve',

        your_name: 'A neved',
        your_email_address: 'Email cím',
        select_a_category: 'Kategória választás',
        select_a_priority: 'Válasszon prioritást',
        assign_to: 'Hozzárendelni',
        leave_us_your_comment: 'Hagyj üzenetet...',
        attach_a_screenshot: 'Képernyőkép mellékelése',
        screenshot_attached: 'screenshot',

        send:    'Küldés',
        back:    'vissza',
        remove:  'Törlés',
        close:   'Bezárás',
        next:    'Tovább',
        send_success: 'Köszönjük a visszajelzést!',

        save:   'Mentés',
        delete: 'Törlés',
        add_your_comment_here: 'Írja ide a megjegyzést...',

        attach_a_file: 'Csatolmány',
        file_format_not_supported: 'Fájl formátum nem támogatott',
        max: 'Max',

        open_feedback: 'visszajelzés',
        search: 'Keresés...',
        cancel: 'Mégse',
        feedback_title: 'Cím hozzáadása',
        feedback_not_found: 'Hoppá, nem találtunk megfelelő visszajelzést.',
        feedback_type_all: 'Összes',
        feedback_type_general: 'Általános visszajelzés',
        feedback_type_bug: 'Hibajelentés',
        feedback_type_feature_request: 'Funkciókérés',
        feedback_type_screenshot: 'Pillanatkép',
        feedback_type_video: 'Képernyő felvétele',
        this_page_only: 'Csak ez az oldal',

        want_to_leave: 'Nem mentett módosításaid vannak',
        changes_not_saved: 'Biztosan bezárod?',
        discard: 'Elvetés',

        start_recording: 'Rajt',

        recording_mute: 'Kapcsolja ki a mikrofont',
        recording_unmute: 'Kapcsolja be a mikrofont',
        recording_pause: 'Szünet',
        recording_resume: 'Folytassa',
        recording_draw: 'Húz',
        recording_finish: 'Befejezni a felvételt'
    },

    // Czech
    'cs': {
        draw_on_the_screen: 'Kreslit na obrazovku',
        draw_on_the_screen_help: 'Umožňuje velmi snadno označit a okomentovat konkrétní části této stránky a odeslat jako obrázek s komentáři',

        general_feedback: 'Popsat chování stránky',
        general_feedback_help: 'Umožňuje velmi snadno komentovat chování konkrétní stránky a odeslat tuto zpětnou vazbu jako požadavek do Vašeho projektu',

        capture_video: 'Natočit video',
        capture_video_help: 'Umožňuje velmi snadno nasnímat chování stránky a odeslat je jako video s komentáři',

        report_bug: 'Nahlásit chybu',
        report_bug_help: 'Dejte nám vědět, co je rozbité',

        feature_request: 'Budoucí žádost',
        feature_request_help: 'Řekněte nám, jak se můžeme zlepšit',

        contact_us: 'Kontaktní info',
        contact_us_help: 'Osobní kontakt',

        view_other_feedback: 'Zobrazit historii požadavků',
        view_other_feedback_help: 'Podívejte se, co již bylo odesláno',

        your_name: 'Vaše jméno',
        your_email_address: 'Vaše emailová adresa',
        select_a_category: 'Výběr kategorie',
        select_a_priority: 'Zvolte prioritu',
        assign_to: 'Přiřadit',
        leave_us_your_comment: 'Vložit komentář...',
        attach_a_screenshot: 'Připojit soubor',
        screenshot_attached: 'screenshot',

        send:    'Odeslat',
        back:    'Zpět',
        remove:  'Odebrat',
        close:   'Zavřít',
        next:    'Další',
        send_success: 'Děkujeme za zpětnou vazbu!',

        save:   'Uložit',
        delete: 'Smazat',
        add_your_comment_here: 'Přidat komentář k této pozici...',

        attach_a_file: 'Přiložit soubor',
        file_format_not_supported: 'Formát souboru není podporován',
        max: 'Max',

        open_feedback: 'požadavků',
        search: 'Vyhledat...',
        cancel: 'Stornovat',
        feedback_title: 'Přidat název',
        feedback_not_found: 'Žádný požadavek nenalezen.',
        feedback_type_all: 'Všechno',
        feedback_type_general: 'Popsat chování stránky',
        feedback_type_bug: 'Zpráva o chybě',
        feedback_type_feature_request: 'Budoucí žádost',
        feedback_type_screenshot: 'Snímek obrazovky',
        feedback_type_video: 'Záznam obrazovky',
        this_page_only: 'Pouze tato stránka',

        want_to_leave: 'Existují neuložené a neodeslané změny.',
        changes_not_saved: 'Opravdu chcete odejít a stornovat údaje?',
        discard: 'Zahodit',

        start_recording: 'Spustit nahrávání',

        recording_mute: 'Vypněte mikrofon',
        recording_unmute: 'Zapněte mikrofon',
        recording_pause: 'Pauza',
        recording_resume: 'Obnovit',
        recording_draw: 'Kreslit',
        recording_finish: 'Dokončete nahrávání'
    },

    // Croatian
    'cr': {
        draw_on_the_screen: 'Crtaj po ekranu',
        draw_on_the_screen_help: 'Pošalji utiske sa slikom ekrana',

        capture_video: 'Snimi video',
        capture_video_help: 'Pošalji utiske sa video snimkom ekrana',

        general_feedback: 'Generalni utisci',
        general_feedback_help: 'Pošalji generalne utiske za ovu stranicu',

        report_bug: 'Prijavite grešku',
        report_bug_help: 'Javite nam što je pokvareno',

        feature_request: 'Zahtjev za značajku',
        feature_request_help: 'Recite nam kako se možemo poboljšati',

        contact_us: 'Kontaktiraj nas',
        contact_us_help: 'Javi nam se',

        view_other_feedback: 'Pogledaj druge prijave',
        view_other_feedback_help: 'Pogledajte što je već bilo poslano',

        your_name: 'Tvoje ime',
        your_email_address: 'Tvoja e-mail adresa',
        select_a_category: 'Odaberi kategoriju',
        select_a_priority: 'Odaberite prioritet',
        assign_to: 'Namijeni za',
        leave_us_your_comment: 'Napiši svoj komentar ovđe',
        attach_a_screenshot: 'Zakači sliku ekrana',
        screenshot_attached: 'screenshot',

        send:    'Pošalji',
        back:    'leđa',
        remove:  'Ukloni',
        close:   'Zatvoriti',
        next:    'Dalje',
        send_success: 'Hvala na povratnoj informaciji!',

        save:   'Sačuvaj',
        delete: 'delete',
        add_your_comment_here: 'Napiši svoj komentar...',

        attach_a_file: 'Zakači fajl',
        file_format_not_supported: 'Format datoteke nije podržan',
        max: 'Maksimum',

        open_feedback: 'prijave',
        search: 'Pretraži...',
        cancel: 'Otkaži',
        feedback_title: 'Dodaj naslov',
        feedback_not_found: 'Hm, ova prijava ne može biti pronađena.',
        feedback_type_all: 'Svi',
        feedback_type_general: 'Generalni',
        feedback_type_bug: 'Izvješće o greškama',
        feedback_type_feature_request: 'Zahtjev za značajku',
        feedback_type_screenshot: 'Sliku ekrana',
        feedback_type_video: 'Video',

        want_to_leave: 'Imate nespremljene promjene',
        changes_not_saved: 'Jeste li sigurni da želite zatvoriti?',
        discard: 'Odbaciti',

        start_recording: 'Započni snimanje',

        recording_mute: 'Isključi mikrofon',
        recording_unmute: 'Uključite mikrofon',
        recording_pause: 'Pauza',
        recording_resume: 'Nastavi',
        recording_draw: 'Crtati',
        recording_finish: 'Završi snimanje',

        video_not_supported: 'Snimanje videa nije podržano',
        video_requires_https: 'Sigurna HTTPS konekcija je neophodna za snimanje videa.',
        ok: 'U redu'
    },

    // Latvian (Latviešu)
    'lv': {
        draw_on_the_screen: 'Zīmēt uz ekrāna',
        draw_on_the_screen_help: 'Izveidot atsauksmi ar ekrānuzņēmumu',

        capture_video: 'Izveidot video',
        capture_video_help: 'Izveidot atsauksmes video ar ekrāna ierakstu',

        general_feedback: 'Vispārīga atsauksme',
        general_feedback_help: 'Nosūtīt vispārīgu atsauksmi par šo lapu',

        report_bug: 'Ziņot par kļūdu',
        report_bug_help: 'Paziņojiet mums, kas ir bojāts',

        feature_request: 'Funkcijas pieprasījums',
        feature_request_help: 'Pastāstiet mums, kā mēs varam uzlabot',

        contact_us: 'Sazināties',
        contact_us_help: 'Sazināties ar mums',

        view_other_feedback: 'Apskatīt pārējās atsauksmes',
        view_other_feedback_help: 'Skatiet, kas jau ir iesniegts',

        your_name: 'Jūsu vārds',
        your_email_address: 'Jūsu e-pasta adrese',
        feedback_title: 'Pievienojiet virsrakstu',
        select_a_category: 'Izvēlēties kategoriju',
        select_a_priority: 'Izvēlieties prioritāti',
        assign_to: 'Uzticēt',
        leave_us_your_comment: 'Atstājiet mums savu komentāru',
        attach_a_screenshot: 'Pievienot ekrānuzņēmumu',
        screenshot_attached: 'Ekrānuzņēmums',

        send:    'Nosūtīt',
        back:    'back',
        remove:  'Remove',
        close:   'Aizvērt',
        next:    'Nākamais',
        send_success: 'Paldies par atsauksmēm!',

        save:   'Saglabāt',
        delete: 'dzēst',
        add_your_comment_here: 'Ievadiet savu komentāru...',

        attach_a_file: 'Pievienojiet failu',
        file_format_not_supported: 'Faila formāts netiek atbalstīts',
        max: 'Maksimums',

        open_feedback: 'feedback',
        search: 'Meklēt...',
        cancel: 'Atcelt',
        feedback_not_found: 'Ups, atsauksme nav atrodama.',
        feedback_type_all: 'Visi',
        feedback_type_general: 'Vispārīga',
        feedback_type_bug: 'Kļūdu ziņojums',
        feedback_type_feature_request: 'Funkcijas pieprasījums',
        feedback_type_screenshot: 'Ekrānuzņēmums',
        feedback_type_video: 'Video',
        this_page_only: 'Tikai šī lapa',

        want_to_leave: 'Jums ir nesaglabātas izmaiņas',
        changes_not_saved: 'Vai esat pārliecināts, ka vēlaties aizvērt?',
        discard: 'Izmest',

        start_recording: 'Sākt ierakstu',

        recording_mute: 'Izslēdziet mikrofonu',
        recording_unmute: 'Ieslēdziet mikrofonu',
        recording_pause: 'Pauze',
        recording_resume: 'Turpināt',
        recording_draw: 'Zīmēt',
        recording_finish: 'Pabeidziet ierakstīšanu',

        video_not_supported: 'Video ierakstīšana netiek atbalstīta',
        video_requires_https: 'Video ierakstīšanai ir nepieciešams drošs HTTPS savienojums.',
        ok: 'Labi'
    },
    'is': {
        draw_on_the_screen: 'Teikna á skjánum',
        draw_on_the_screen_help: 'Veittu endurgjöf með skjáskoti',

        general_feedback: 'Almennar athugasemdir',
        general_feedback_help: 'Almennar athugasemdir fyrir þessa síðu',

        capture_video: 'Gerðu myndband',
        capture_video_help: 'Veittu endurgjöf með myndbandi',

        report_bug: 'Tilkynna um villu',
        report_bug_help: 'Segðu okkur hvað virkar ekki sem skyldi',

        feature_request: 'Ósk um virkni',
        feature_request_help: 'Segðu okkur hvernig við getum bætt okkur',

        contact_us: 'Hafðu samband',
        contact_us_help: 'Hafðu samband við okkur',

        view_other_feedback: 'Skoðaðu aðrar athugasemdir',
        view_other_feedback_help: 'Skoðaðu hvað hefur þegar verið sent inn',

        your_name: 'Nafn',
        your_email_address: 'Netfang',
        select_a_category: 'Veldu flokk',
        select_a_priority: 'Veldu forgang',
        assign_to: 'Úthlutað til',
        leave_us_your_comment: 'Skildu eftir skilaboð...',
        attach_a_screenshot: 'Setja skjáskot í viðhengi',
        screenshot_attached: 'screenshot',

        send:    'Senda',
        back:    'aftur',
        remove:  'Fjarlægja',
        close:   'Loka',
        next:    'Áfram',
        send_success: 'Takk fyrir endurgjöfina!',

        save:   'Vista',
        delete: 'Eyða',
        add_your_comment_here: 'Sláðu inn athugasemdir...',

        attach_a_file: 'Setja skrá í viðhengi',
        file_format_not_supported: 'Skráarsnið er ekki stutt',
        max: 'Hám',

        open_feedback: 'opnar athugasemdir',
        search: 'Leita...',
        cancel: 'Hætta við',
        feedback_title: 'Bæta við titli',
        feedback_not_found: 'Úps, engar athugasemdir fundust',
        feedback_type_all: 'Allt',
        feedback_type_general: 'Almenn endurgjöf',
        feedback_type_bug: 'Villuskýrsla',
        feedback_type_feature_request: 'Ósk um virkni',
        feedback_type_screenshot: 'Skjámynd',
        feedback_type_video: 'Skjáupptaka',
        this_page_only: 'Aðeins þessi síða',

        want_to_leave: 'Þú hefur óvistaðar breytingar',
        changes_not_saved: 'Ertu viss um að þú viljir loka?',
        discard: 'Henda',

        start_recording: 'Hefja upptöku',

        recording_mute: 'Slökkva á hljóði',
        recording_unmute: 'Kveikja á hljóði',
        recording_pause: 'Pása',
        recording_resume: 'Halda áfram',
        recording_draw: 'Teikna',
        recording_finish: 'Ljúka upptöku',

        video_not_supported: 'Myndbandstaka er ekki studd',
        video_requires_https: 'Örugg tenging er nauðsynleg til að taka upp myndband.',
        ok: 'Í lagi'
    },

    setLang: function(language) {
        this.language = language;
    },

    get: function(key, include_fallback) {
        if (!Lang[Lang.language]) {
            Lang.language = 'en';
        }

        if (Lang[Lang.language][key]) {
            return Lang[Lang.language][key];
        } else {
            if (include_fallback) {
                return Lang['en'][key] || '';
            } else {
                return '';
            }
        }
    }
};

export default Lang;
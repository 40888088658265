import $ from 'jquery';
import Session_Recorder from 'component/session_recorder';

// Collection of subscribed rageclick listeners
const userback_handlers = [];
// All tracked events
const click_events = [];

// Internal function for triggering window.Userback events
function rage_trigger(payload) {
    for (const handler of userback_handlers) {
        handler.handler(payload);
    }
}

function getElementName(node) {
    let element = '';
    let attributes = '';
    if (node && node.tagName) {
        for (const attr of node.attributes) {
            attributes += ' ' + attr.name + '="' + attr.value +  '"';
        }
        element = node.tagName ? '<' + node.tagName.toLowerCase() + attributes + ' />' : '';
    }
    return element;
}

// Mouse handler attached to the document for detecting rage clicks
const on_mouse_interaction = () => (event) => {
    if (!['mouse', 'pen', 'touch'].includes(event.pointerType)) {
        return;
    }
    // Rage click detection
    const timestamp = Date.now();
    click_events.push({ timestamp, type: 'click', x: event.clientX, y: event.clientY });

    // Cleanup events once we have more than 100
    if (click_events.length > 100) {
        click_events.splice(0, click_events.length - 100);
    }

    // Check for Rage Clicks in reverse
    let rage_counter = 0;
    let throttle_rage = false;
    for (let i = click_events.length - 1; i >= 0; i -= 1) {
        const e = click_events[i];
        // Anything after 3 seconds is not relevant
        if (e.timestamp <= timestamp - 3000) {
            break;
        }
        // Supress new events if a rage has been recently recorded
        if (e.type === 'rage_click') {
            throttle_rage = true;
        }
        // Click within a very recent threshold, add to the rage counter
        if (e.timestamp >= timestamp - 800 && e.type === 'click') {
            rage_counter += 1;
        }
    }
    if (!throttle_rage && rage_counter >= 4) {
        // Check if the x/y cords are within the same 50px radius
        const prev_events = click_events.slice(-4);
        const { x, y } = prev_events[0];
        let rage_distance = 0;
        // Calculate the euclidean distance between each click
        for (let i = 1; i < prev_events.length; i += 1) {
            const e = prev_events[i];
            rage_distance += Math.sqrt((e.x - x) ** 2 + (e.y - y) ** 2);
        }
        // If the distance is less than 100px, we have a valid rage click
        if (rage_distance < 100) {
            click_events.push({ timestamp, type: 'rage_click' });
            const element_name = getElementName(event.target);
            Session_Recorder.addCustomEvent('rage_click', { element: element_name });
            rage_trigger({
                element: element_name,
                event: event,
                timestamp
            });
        }
    }
};

// Internal reference to the Userback.Rage object
let Userback_Rage;

// Initialize the Userback.Rage object by first capturing the Userback object, saving
// a reference of the Rage module and returning it.
function Userback_Rage_init() {
    // Userback.Rage
    const handle_pointer = on_mouse_interaction();

    Userback_Rage = {
        on: (handler) => {
            if (typeof handler !== 'function') {
                return console.error('Invalid handler: must be a function');
            }
            userback_handlers.push({ handler });
            return undefined;
        },
        start: function () {
            // Start recording
            document.addEventListener('pointerdown', handle_pointer, { capture: true, passive: true });
        },
        pause: function () {
            document.removeEventListener('pointerdown', handle_pointer, { capture: true, passive: true });
        },
        stop: () => {
            Userback_Rage.pause();
            click_events.splice(0, click_events.length);
        }
    };

    // Auto-start
    $(() => {
        Userback_Rage.start();
    });

    return Userback_Rage;
}

export default Userback_Rage_init;

import {
    DEVICE,
    PAGE_CONDITION,
    POSITION,
    SIZE,
    TRIGGER_TYPE
} from './constant';

export function isValidateTriggerType(type) {
    if (!type) {
        return false;
    }

    if (!TRIGGER_TYPE.includes(type)) {
        return false;
    }

    return true;
}

export function isValidateFormat(format) {
    if (format === 'paged' || format === 'pageless') {
        return true;
    }

    return false;
}

export function isValidateTriggerDelay(delay) {
    if (delay === null || delay === undefined) {
        return false;
    }

    if (typeof delay !== 'number') {
        return false;
    }

    if (delay < 0) {
        return false;
    }

    return true;
}

export function isValidateTriggerAction(action) {
    // TODO: Phase 2
    // scroll_half_down, leave_page
    return true;
}

export function isValidateCondition(condition) {
    const json_config = condition;

    if (typeof json_config === 'object') {
        // Check whether config is empty
        if (Object.keys(json_config).length === 0) return true;

        const valid_config = validateConditionGroup(json_config);

        if (valid_config) return true;
    }

    return false;
}

export function isValidateSurveySize(size) {
    if (!size) {
        return false;
    }

    if (!SIZE.includes(size)) {
        return false;
    }

    return true;
}

export function isValidateFrequency(frequency) {
    if (typeof frequency !== 'string') {
        return false;
    }

    // only format like '', 'always', 'once', 'once_<days>', 'oncesubmit', 'oncesubmit_<days>'
    if (frequency !== '' && frequency !== 'always' && !frequency.match(/^(once|oncesubmit|close)(_[0-9]+)?$/)) {
        return false;
    }

    return true;
}

export function isValidatePosition(position) {
    if (!position) {
        return false;
    }

    if (!POSITION.includes(position)) {
        return false;
    }

    return true;
}

export function isValidateDevice(device) {
    if (!device) {
        return false;
    }

    if (typeof device !== 'string') {
        return false;
    }

    const devices = device.split(',');

    // Loop through each device and validate its value
    for (const device of devices) {
        if (!DEVICE.includes(device)) {
            return false;
        }
    }

    return true;
}

export function isValidateUserSegment(segment) {
    if (segment === '') {
        return true;
    }

    // check if segment is an array
    if (!Array.isArray(segment)) {
        return false;
    }

    // check if segement item is object
    if (!segment.every(item => typeof item === 'object')) {
        return false;
    }

    return true;
}

export function isValidateBackgroundColour(colour) {
    if (!colour) {
        return false;
    }

    if (typeof colour !== 'string') {
        return false;
    }

    const [bg_colour, bg_opacity] = colour.split(',');
    if (!bg_colour || !bg_opacity) {
        return false;
    }

    if (bg_opacity < 0 || bg_opacity > 100) {
        return false;
    }

    return true;
}

function validateConditionGroup(group) {
    const validOperators = ['and', 'or'];

    if (!group || !group.operator || !group.conditions || !Array.isArray(group.conditions)) {
        return false;
    }
    if (!validOperators.includes(group.operator.toLowerCase())) {
        return false;
    }

    return group.conditions.every(validateCondition);
}

function validateCondition(condition) {
    return (
        condition &&
        condition.type &&
        condition.value &&
        PAGE_CONDITION.includes(condition.type.toLowerCase())
    );
}

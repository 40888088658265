import Bowser from 'bowser';

const bowser_parser = Bowser.getParser(window.navigator.userAgent);

export function isDesktop() {
    return bowser_parser.getPlatformType() === 'desktop' || bowser_parser.getOSName().toLowerCase() === 'chrome os';
}

export function isTablet() {
    return bowser_parser.getPlatformType() === 'tablet';
}

export function isMobile() {
    return bowser_parser.getPlatformType() === 'mobile';
}
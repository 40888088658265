import $ from 'jquery';
import Lang from 'lib/lang';
import Svg_Icons from 'lib/svg_icons';

const Modal = {
    modalMessage: function(message) {
        var modal_overlay = $('umodal');

        if (!modal_overlay.length) {
            modal_overlay = $('<umodal>').appendTo($(document.body));
        }

        modal_overlay.empty();

        var modal = $('<ubdiv>').html(message).appendTo(modal_overlay);
        var modal_close = $('<uclose>').addClass('userback-modal-close').html(Svg_Icons.times).appendTo(modal);

        var _documentKeyup = (e) => {
            if (e.which === 27) {
                this.modalClose();
                $(document).off('keyup', _documentKeyup);
            }
        };

        $(document).on('keyup', _documentKeyup);
        modal_close.on('click', this.modalClose);

        $(document.body).addClass('userback-modal-open');

        return modal_overlay;
    },

    modalConfirm: function(message, confirm_func, button_text) {
        button_text = button_text || Lang.get('discard');

        var buttons =
        '<div class="userback-modal-buttons">' +
            '<btn>' + Lang.get('cancel') + '</btn>' +
            '<btn class="userback-button-input">' + button_text + '</btn>' +
        '</div>';

        var modal_overlay = this.modalMessage(message + buttons);

        modal_overlay.find('.userback-modal-buttons btn:first').on('click', this.modalClose);
        modal_overlay.find('.userback-modal-buttons btn:last').on('click', confirm_func);
    },

    modalClose: function() {
        $('umodal').remove();
        $(document.body).removeClass('userback-modal-open');
    },
};

export default Modal;
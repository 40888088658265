import $ from 'jquery';
import Lang from 'lib/lang';
import Scripts from 'lib/scripts';
import Svg_Icons from 'lib/svg_icons';
import Modal from 'component/modal';

const Video_Capture = {
    device_list: {
        audioinput:  [],
        videoinput:  [],
        audiooutput: [],
        videooutput: []
    },

    recorded_blobs  : [],
    recorder        : null,

    audio_script_processor: null,

    screen_stream   : null,
    voice_stream    : null,
    combined_stream : null,

    options: {
        onSubmit: null,
        onCancel: null
    },

    timer_timeout: null, // 2 min timer
    timer: 180, // 180 seconds it decreases as time goes
    timer_total: 180, // does not change

    count_down_timeout: null, // 3 second count down
    count_down: 3, // 3 seconds

    is_muted: false,
    draw_mode: false,

    annotations: [],

    annotation_timeline: [],

    snap: null, // snap svg

    mime_type: 'video/webm; codecs="vp8, opus"', // this is the codecs supported in both Chrome and Firefox

    submit_timeout: null,

    isOpen: function() {
        return this.controls ? true : false;
    },

    start: function(options) {
        this.options.onSubmit = options.onSubmit || null;
        this.options.onCancel = options.onCancel || null;

        this.timer = options.timer || 180;
        this.timer = Math.min(300, this.timer); // max: 5 mins
        this.timer = Math.max(60,  this.timer); // min: 1 min

        this.timer_total = this.timer;

        this.reset();
        this.getDevice(this.createControls.bind(this));
    },

    reset: function() {
        this.recorded_blobs  = [];
        this.recorder        = null;

        this.audio_script_processor = null;

        this.screen_stream   = null;
        this.voice_stream    = null;
        this.combined_stream = null;

        this.is_muted  = false;
        this.draw_mode = false;

        if (this.controls) {
            this.pause_btn.html(Svg_Icons.pause);
        }

        this.snap = null;
    },

    getDevice: function(callback) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            callback();
            return;
        }

        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                devices.forEach((device) => {
                    switch (device.kind) {
                        case 'audioinput':
                            this.device_list.audioinput.push(device);
                            break;
                        case 'videoinput':
                            this.device_list.videoinput.push(device);
                            break;
                        case 'audiooutput':
                            this.device_list.audiooutput.push(device);
                            break;
                        case 'videooutput':
                            this.device_list.videooutput.push(device);
                            break;
                    }
                });

                callback();
            })
            .catch(function(error) {
            });
    },

    createControls: function() {
        this.controls_overlay = $('<ubdiv>')
            .attr('id', 'userback_video_container')
            .appendTo($(document.body));

        this.controls = $('<ubvideotoolbar>').appendTo($(document.body));

        this.mute_btn   = $('<btn>').addClass('userback-video-controls-mute userback-video-controls-active')
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('recording_mute', true) + '</btn-name>' +
                                        '<btn-shortcut>Shift + M</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.mic).appendTo(this.controls);

        this.draw_btn   = $('<btn>').addClass('userback-video-controls-draw')
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('recording_draw', true) + '</btn-name>' +
                                        '<btn-shortcut>Shift + D</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.highlighter).appendTo(this.controls);

        this.pause_btn  = $('<btn>').addClass('userback-video-controls-pause')
                                .attr('disabled', true)
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('recording_pause', true) + '</btn-name>' +
                                        '<btn-shortcut>Shift + P</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.pause).appendTo(this.controls);

        this.start_btn  = $('<btn>').addClass('userback-video-controls-start')
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('start_recording', true) + '</btn-name>' +
                                        '<btn-shortcut>Shift + S</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' +
                                    '<btn-record></btn-record>').appendTo(this.controls);

        this.stop_btn   = $('<btn>').addClass('userback-video-controls-stop')
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('recording_finish', true) + '</btn-name>' +
                                        '<btn-shortcut>Shift + S</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' +
                                    '<btn-timer><btn-timer-mask></btn-timer-mask></btn-timer>' + Svg_Icons.stop).appendTo(this.controls);

        this.timer_btn  = $('<btn>').addClass('userback-video-controls-timer')
                                .text(this.getTimerText()).appendTo(this.controls);

        this.close_btn  = $('<btn>').addClass('userback-video-controls-close')
                                .html('<btn-tooltip>' +
                                        '<btn-name>' + Lang.get('cancel', true) + '</btn-name>' +
                                        '<btn-shortcut>Esc</btn-shortcut>' +
                                    '</btn-tooltip>' +
                                    '<btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.times).appendTo(this.controls);

        this.mic_volume = $('<ubvolume>' +
                                '<ubdiv></ubdiv>' +
                                '<ubdiv></ubdiv>' +
                                '<ubdiv></ubdiv>' +
                            '</ubvolume>')
                            .appendTo(this.mute_btn);

        if (!this.device_list.audioinput.length) {
            this.is_muted = true;
            this.mute_btn.removeClass('userback-video-controls-active').attr('disabled', true);
        }

        this.stop_btn.find('btn-timer, btn-timer-mask').css('animation-duration', (this.timer + 's'));

        this.start_btn.on('click', this.startCapture.bind(this));
        this.stop_btn.on('click',  this.stopCapture.bind(this));
        this.close_btn.on('click', this.cancelCapture.bind(this));
        this.pause_btn.on('click', this.pauseRecording.bind(this));
        this.mute_btn.on('click',  this.muteVoice.bind(this));
        this.draw_btn.on('click',  this.toggleDrawMode.bind(this));

        // Tooltip
        this.mute_btn.on('mouseenter',  this.showBtnTooltip.bind(this));
        this.mute_btn.on('mouseleave',  this.hideBtnTooltip.bind(this));
        this.draw_btn.on('mouseenter',  this.showBtnTooltip.bind(this));
        this.draw_btn.on('mouseleave',  this.hideBtnTooltip.bind(this));

        this.pause_btn.on('mouseenter', this.showBtnTooltip.bind(this));
        this.pause_btn.on('mouseleave', this.hideBtnTooltip.bind(this));
        this.start_btn.on('mouseenter', this.showBtnTooltip.bind(this));
        this.stop_btn.on('mouseleave',  this.hideBtnTooltip.bind(this));
        this.stop_btn.on('mouseenter',  this.showBtnTooltip.bind(this));
        this.start_btn.on('mouseleave', this.hideBtnTooltip.bind(this));
        this.close_btn.on('mouseenter', this.showBtnTooltip.bind(this));
        this.close_btn.on('mouseleave', this.hideBtnTooltip.bind(this));

        this.controls.on('mousedown.videodrag', this.events.toolbarMouseDown.bind(this));

        $(document).on('mousemove.videodrag', this.events.documentMouseMove.bind(this));
        $(document).on('mouseup.videodrag',   this.events.documentMouseUp.bind(this));
        $(document).on('mousedown.video',     this.events.documentMouseDown.bind(this));
        $(document).on('keydown.video',       this.events.documentKeyDown.bind(this));
    },

    events: {
        toolbarMouseDown: function(e) {
            if ($(e.target).prop('tagName').toLowerCase() !== 'ubvideotoolbar') {
                return;
            }

            this.toolbar_drag_start = true;

            this.toolbar_drag_x = e.pageX;
            this.toolbar_drag_y = e.pageY;

            this.toolbar_left   = parseInt(this.controls.css('left'),   10);
            this.toolbar_bottom = parseInt(this.controls.css('bottom'), 10);
        },

        documentMouseMove: function(e) {
            if (!this.toolbar_drag_start) {
                return;
            }

            var left   = this.toolbar_left + (e.pageX - this.toolbar_drag_x);
            var bottom = this.toolbar_bottom - (e.pageY - this.toolbar_drag_y);

            left = Math.max(12, left);
            left = Math.min($(window).width() - 12 - this.controls.innerWidth(), left);

            bottom = Math.max(12, bottom);
            bottom = Math.min($(window).height() - 12 - this.controls.innerHeight(), bottom);

            this.controls.css({
                left   : left,
                bottom : bottom
            });
        },

        documentMouseDown: function(e) {
            if (!this.recorder) {
                return;
            }

            if (this.draw_mode) {
                return;
            }

            if ($(e.target).parents('ubvideotoolbar').length ||
                $(e.target).prop('tagName').toLowerCase() === 'ubvideotoolbar') {
                return;
            }

            var mouse_click = $('<ubmouseclick>').appendTo($(document.body));

            mouse_click.css({
                top: e.pageY - $(window).scrollTop(),
                left: e.pageX - $(window).scrollLeft()
            });

            setTimeout(function() {
                mouse_click.remove();
            }, 500);
        },

        documentMouseUp: function(e) {
            this.toolbar_drag_start = false;
        },

        documentKeyDown: function(e) {
            var tag_name = $(e.target).prop('tagName').toLowerCase();

            if (['input', 'textarea'].indexOf(tag_name) !== -1) {
                return;
            }

            switch (e.which) {
                case 27: // ESC
                    this.close_btn.trigger('click');
                    break;
                case 68: // d
                    if (e.shiftKey) {
                        this.draw_btn.trigger('click');
                    }
                    break;
                case 77: // m
                    if (e.shiftKey) {
                        this.mute_btn.trigger('click');
                    }
                    break;
                case 80: // p
                    if (e.shiftKey) {
                        this.pause_btn.trigger('click');
                    }
                    break;
                case 83: // s
                    if (e.shiftKey) {
                        if (this.start_btn.is(':visible')) {
                            this.start_btn.trigger('click');
                        } else {
                            this.stop_btn.trigger('click');
                        }
                    }
                    break;
            }
        }
    },

    showBtnTooltip: function(e) {
        if (this.toolbar_drag_start) {
            return;
        }

        $(e.currentTarget).find('btn-tooltip, btn-tooltip-arrow').show();

        var left  = $(e.currentTarget).find('btn-tooltip').offset().left;
        var right = parseInt(left, 10) + parseInt($(e.currentTarget).find('btn-tooltip').outerWidth(), 10);

        if (left < 0) {
            $(e.currentTarget).find('btn-tooltip').css('margin-left', Math.ceil(left) * -1 + 5);
        } else if (right > $(window).width()) {
            $(e.currentTarget).find('btn-tooltip').css('margin-left', (right - $(window).width()) * -1 - 5);
        }
    },

    hideBtnTooltip: function(e) {
        $(e.currentTarget).find('btn-tooltip, btn-tooltip-arrow').hide();
        $(e.currentTarget).find('btn-tooltip').css('margin-left', '');
    },

    removeControls: function() {
        this.controls_overlay.remove();
        this.controls.remove();
        this.controls_overlay = null;
        this.controls = null;

        // remove mic analyser
        if (this.audio_script_processor && typeof this.audio_script_processor._disconnect !== 'undefined') {
            this.audio_script_processor._disconnect();
        }

        $('ubmouseclick').remove();
        $(document).off('mousedown.video');
        $(document).off('keydown.video');

        $(document).off('mousedown.videodrag');
        $(document).off('mousemove.videodrag');
    },

    muteVoice: function() {
        if (this.mute_btn.attr('disabled')) {
            return;
        }

        this.is_muted = !this.is_muted;

        if (this.is_muted) {
            this.mute_btn.removeClass('userback-video-controls-active');
            this.mute_btn.find('btn-name').text(Lang.get('recording_unmute', true));
        } else {
            this.mute_btn.addClass('userback-video-controls-active');
            this.mute_btn.find('btn-name').text(Lang.get('recording_mute', true));
        }

        this.toggleAudio();
    },

    toggleDrawMode: function() {
        if (this.draw_btn.attr('disabled')) {
            return;
        }

        this.draw_mode = !this.draw_mode;
        this.draw_btn.toggleClass('userback-video-controls-active');

        if (this.draw_mode) {
            this.addOverlay();
        } else {
            this.removeOverlay();
        }
    },

    toggleAudio: function() {
        if (!this.combined_stream) {
            return;
        }

        var audio_tracks = this.combined_stream.getAudioTracks();
        if (audio_tracks.length) {
            audio_tracks.forEach((audio_track) => {
                audio_track.enabled = !this.is_muted;
            });
        }
    },

    addOverlay: async function() {
        await Scripts.loadSnapSvg();

        var dpr = typeof window.devicePixelRatio === 'undefined' ? 1 : window.devicePixelRatio;

        $('ubvideooverlay').remove();

        this.overlay = $('<ubvideooverlay>');
        this.overlay.attr({
            dpr: dpr.toFixed(2),
            recording: this.recorder ? 'true' : 'false'
        });
        this.overlay.append('<svg id="snap_svg_video" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"></svg>');
        this.overlay.css('height', $(document).height());
        this.overlay.appendTo($(document.body));

        this.snap = Userback.Snap('#snap_svg_video');

        // mouse
        this.snap.drag(this.dragMove.bind(this), this.dragStart.bind(this), this.dragStop.bind(this));

        // touch
        this.snap.touchstart(this.dragStart.bind(this));
        this.snap.touchmove(this.dragMove.bind(this));
        this.snap.touchcancel(this.dragStop.bind(this));
        this.snap.touchend(this.dragStop.bind(this));

        $(window).on('resize.video', () => {
            if (this.overlay) {
                this.overlay.css('height', 0);
                this.overlay.css('height', $(document).height());
            }
        });

        $(window).on('scroll.video', () => {
            if (this.annotation_clear_timeout) {
                return;
            }

            this.annotation_clear_timeout = setTimeout(() => {
                this.annotations.forEach(function(obj) {
                    obj.animate({opacity: 0}, 1000);
                });

                this.annotations = [];

                this.annotation_clear_timeout = null;
            }, 2000);
        });
    },

    removeOverlay: function() {
        $('ubvideooverlay').remove();

        $(window).off('scroll.video');
        $(window).off('resize.video');
    },

    dragStart: function() {
        if (!this.recorder) {
            return;
        }

        if (this.draw_started) {
            return;
        }

        clearTimeout(this.annotation_clear_timeout);
        this.annotation_clear_timeout = null;

        var x, y, event;

        if (typeof arguments[0] === 'object') {
            event = arguments[0];
        } else {
            event = arguments[2];
        }

        // avoid half pixel issue
        x = parseInt(event.offsetX, 10);
        y = parseInt(event.offsetY, 10);

        this.draw_started = true;

        this.svg_obj_path = this.snap.path('M'+ x +',' + y);
        this.svg_obj_path.attr({
            'stroke'          : '#E80000',
            'stroke-width'    : 15,
            'stroke-linecap'  : 'round',
            'stroke-linejoin' : 'round',
            'fill'            : 'transparent',
            'opacity'         : 0.8
        });

        this.stop_x  = x;
        this.stop_y  = y;

        this.annotation_timeline.push(this.timer_total - this.timer);
    },

    dragMove: function() {
        if (!this.recorder) {
            return;
        }

        if (!this.draw_started) {
            return;
        }

        var x, y, event;

        if (typeof arguments[0] === 'object') {
            event = arguments[0];
        } else {
            event = arguments[4];
        }

        x = event.pageX - this.overlay.offset().left;
        y = event.pageY - this.overlay.offset().top;

        this.stop_x = x;
        this.stop_y = y;

        // don't draw off the screen
        this.stop_x = Math.max(6, this.stop_x);
        this.stop_y = Math.max(6, this.stop_y);
        this.stop_x = Math.min(this.snap.node.clientWidth - 6,  this.stop_x);
        this.stop_y = Math.min(this.snap.node.clientHeight - 6, this.stop_y);

        var current_path = this.svg_obj_path.attr('path');
        var new_path = current_path + 'L' + this.stop_x + ',' + this.stop_y;

        this.svg_obj_path.attr('path', new_path);
    },

    dragStop: function(event) {
        if (!this.recorder) {
            return;
        }

        if (!this.draw_started) {
            return;
        }

        var bbox = this.svg_obj_path.getBBox();

        if (bbox.width === 0 || bbox.height === 0) {
            this.annotation_timeline.pop();
        }

        this.annotations.push(this.svg_obj_path);

        this.draw_started = false;

        this.stop_x  = 0;
        this.stop_y  = 0;

        this.svg_obj_path = false;
    },

    startCapture: function() {
        var displayMediaOptions = {
            video: {
                cursor: 'always'
            },
            audio: false,
            selfBrowserSurface: 'include'
        };

        var userMediaOptions = {
            audio: true,
            video: false
        };

        try {
            var _afterGetVideoStream = () => {
                // when clicking the stop share button
                this.screen_stream.getTracks()[0].onended = () => {
                    this.stopCapture();
                };

                var count_down = this.count_down;

                var _timer = function() {
                    if (count_down === 0) {
                        this.controls_overlay.hide();

                        $('.userback-video-count-down').remove();

                        this.startRecording();
                    } else {
                        $('<utimer>').addClass('userback-video-count-down').text(count_down).appendTo($(document.body));

                        count_down--;

                        this.count_down_timeout = setTimeout(_timer.bind(this), 1000);
                    }
                };

                _timer.call(this);

                this.stop_btn.show();
                this.timer_btn.show();
                this.start_btn.hide();
                this.close_btn.hide();
            };

            var _afterGetVoiceStream = () => {
                if (this.voice_stream) {
                    navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
                        .then((stream) => {
                            this.screen_stream = stream;

                            var screen_tracks = this.screen_stream.getTracks();
                            var voice_tracks  = this.voice_stream.getTracks();

                            this.combined_stream = new MediaStream(screen_tracks.concat(voice_tracks));

                            _afterGetVideoStream();
                        })
                        .catch(this.handleCaptureError.bind(this));
                } else {
                    navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
                        .then((stream) => {
                            this.screen_stream = stream;

                            this.combined_stream = this.screen_stream;

                            // disabled mic button
                            this.is_muted = true;
                            this.mute_btn.removeClass('userback-video-controls-active').attr('disabled', true);

                            _afterGetVideoStream();
                        })
                        .catch(this.handleCaptureError.bind(this));
                }
            };

            if (this.device_list.audioinput.length) {
                navigator.mediaDevices.getUserMedia(userMediaOptions)
                    .then((stream) => {
                        this.voice_stream = stream;

                        _afterGetVoiceStream();

                        try {
                            var audio_context           = new AudioContext();
                            var analyser                = audio_context.createAnalyser();
                            var microphone              = audio_context.createMediaStreamSource(stream);
                            this.audio_script_processor = audio_context.createScriptProcessor(2048, 1, 1);

                            analyser.smoothingTimeConstant = 0.8;
                            analyser.fftSize = 1024;

                            microphone.connect(analyser);
                            analyser.connect(this.audio_script_processor);
                            this.audio_script_processor.connect(audio_context.destination);

                            this.audio_script_processor._disconnect = () => {
                                this.audio_script_processor.disconnect(audio_context.destination);
                            };

                            this.audio_script_processor.onaudioprocess = () => {
                                var values = 0;
                                var array = new Uint8Array(analyser.frequencyBinCount);
                                analyser.getByteFrequencyData(array);

                                for (var i = 0; i < array.length; i++) {
                                    values += (array[i]);
                                }

                                var average = values / array.length;
                                var volume  = Math.floor(average / 150 * 10); // max 150

                                this.mic_volume.toggleClass('hasvolume', volume > 0);
                            };
                        } catch (e) {
                            // api is not supported: ignore
                        }
                    })
                    .catch(() => {
                        // cant get microphone: DOMException: Permission denied
                        _afterGetVoiceStream();
                    });
            } else {
                _afterGetVoiceStream();
            }
        } catch (e) {
            this.handleCaptureError(e);
        }
    },

    stopCapture: function() {
        if (this.recorder) {
            this.submitRecording();
        } else {
            this.stopRecording();

            this.removeControls();
            this.removeOverlay();
            this.reset();

            if (this.options.onCancel) {
                this.options.onCancel();
            }
        }
    },

    cancelCapture: function() {
        this.stopRecording();

        this.removeControls();
        this.removeOverlay();
        this.reset();

        if (this.options.onCancel) {
            this.options.onCancel();
        }
    },

    handleCaptureError: function(e) {
        if (typeof e.name !== 'undefined' && e.name === 'NotAllowedError') {
            if (e.message.toLowerCase() === 'permission denied by system') {
                Modal.modalConfirm('<div style="margin-bottom: 8px;"><b>Permission denied</b></div><div class="userback-modal-info-text">Please allow screen recording permission for the browser.</div>', function() {
                    Modal.modalClose();
                }, 'OK');
            }

            // browser permission is not allowed
            this.stopCapture();
        } else {
            // when video recording (browser API) is not supported in the browser
            Modal.modalConfirm('<div style="margin-bottom: 8px;"><b>Video recording is not supported in your browser</b></div><div class="userback-modal-info-text">Please download the latest version of <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/firefox/download/thanks/">Firefox</a> or <a href="http://microsoft.com/en-us/edge">Microsoft Edge</a>.</div>', function() {
                Modal.modalClose();
            }, 'OK');
        }
    },

    getTimerText: function() {
        var min = Math.floor(this.timer / 60);
        var sec = this.timer - min * 60;

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return min + ':' + sec;
    },

    startTimer: function() {
        this.stop_btn.find('btn-timer, btn-timer-mask').css('animation-play-state', 'running');

        this.timer_timeout = setInterval(() => {
            var timer_text = this.getTimerText();
            this.timer_btn.text(timer_text);
            this.timer--;

            if (timer_text === '00:00') {
                clearInterval(this.timer_timeout);
                this.submitRecording();
            }
        }, 1000);
    },

    stopTimer: function() {
        clearInterval(this.timer_timeout);
    },

    stopCountDown: function() {
        clearTimeout(this.count_down_timeout);
    },

    startRecording: function() {
        try {
            // vp8 and opus are supported by Chrome and Firefox
            this.recorder = new MediaRecorder(this.combined_stream, {
                mimeType: this.mime_type
            });
        } catch (e) {
            return;
        }

        this.toggleAudio();

        this.recorder.addEventListener('dataavailable', this.handleDataAvailable.bind(this));
        this.recorder.start(1000); // collect 1000ms of data in each blob

        this.pause_btn.removeAttr('disabled');

        this.draw_btn.removeAttr('disabled');

        if (this.overlay) {
            this.overlay.attr('recording', this.recorder ? 'true' : 'false');
        }

        this.startTimer();

        window.addEventListener('beforeunload', (e) => {
            if (!this.recorder) {
                return;
            }

            // Cancel the event
            e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
            // Chrome requires returnValue to be set
            e.returnValue = '';
        });
    },

    stopRecording: function() {
        if (this.recorder) {
            this.recorder.stop();
        }

        if (this.combined_stream) {
            this.combined_stream.getTracks().forEach(function(track) {
                track.stop();
            });
        }

        this.stopTimer();
        this.stopCountDown();
    },

    pauseRecording: function() {
        if (this.pause_btn.attr('disabled')) {
            return;
        }

        if (!this.recorder) {
            return;
        }

        switch (this.recorder.state) {
            case 'recording':
                this.pause_btn.html('<btn-tooltip><btn-name>' + Lang.get('recording_resume', true) + '</btn-name><btn-shortcut>Shift + P</btn-shortcut></btn-tooltip><btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.resume);
                this.recorder.pause();

                this.stop_btn.find('btn-timer, btn-timer-mask').css('animation-play-state', 'paused');

                this.stopTimer();
                break;
            case 'paused':
                this.pause_btn.html('<btn-tooltip><btn-name>' + Lang.get('recording_pause', true) + '</btn-name><btn-shortcut>Shift + P</btn-shortcut></btn-tooltip><btn-tooltip-arrow></btn-tooltip-arrow>' + Svg_Icons.pause);
                this.recorder.resume();

                this.stop_btn.find('btn-timer, btn-timer-mask').css('animation-play-state', 'running');

                this.startTimer();
                break;
        }
    },

    submitRecording: function() {
        this.stopRecording();
        this.removeControls();
        // Ensure we finish up and submit what we have incase we don't recieve a final blob in handleDataAvailable
        this.submit_timeout = setTimeout(this.finaliseRecording.bind(this), 200);
    },

    finaliseRecording: function() {
        if (this.options.onSubmit) {
            var video_blob = new Blob(this.recorded_blobs, {type: 'video/webm'});
            this.options.onSubmit(video_blob, this.annotation_timeline);
        }
        this.removeOverlay();
        this.reset();
    },

    handleDataAvailable: function(e) {
        if (e.data && e.data.size > 0) {
            this.recorded_blobs.push(e.data);
        }
        if (this.recorder.state === 'innactive' && this.submit_timeout !== null){
            clearTimeout(this.submit_timeout)
            this.finaliseRecording()
        }
    }
};

export default Video_Capture;
